import {Type} from '@angular/core';
import {
  STICKY_EVENT_TYPE_SUCCESS,
  STICKY_EVENT_TYPE_WARNING,
  STICKY_EVENT_TYPE_ERROR,
  STICKY_EVENT_TYPE_COMPONENT
} from './sticky.constants';

export interface StickyEventInterface {
  type?: string;
  title?: string;
  body?: string;
  footer?: string;
  actions?: StickyEventActionInterface;
  autoHide?: number | boolean;
  component?: Type<any>;
  context?: string;
}

export interface StickyEventActionInterface {
  ok?: StickyEventActionButtonInterface;
  cancel?: StickyEventActionButtonInterface;
}

export interface StickyEventActionButtonInterface {
  label: string;
  action: any;
}

export abstract class StickyEventType implements StickyEventInterface {
  type: string;
  title: string;
  body: string;
  footer: string;
  actions: StickyEventActionInterface;
  autoHide: number | boolean;
  component = null;
  context: string;

  constructor({title, body, footer, actions, autoHide, context}: StickyEventInterface) {
    this.title = title;
    this.body = body;
    this.footer = footer;
    this.actions = actions;
    this.autoHide = autoHide;
    this.context = context;
  }
}

export class StickyEventSuccess extends StickyEventType {
  type = STICKY_EVENT_TYPE_SUCCESS;
}

export class StickyEventWarning extends StickyEventType {
  type = STICKY_EVENT_TYPE_WARNING;
}

export class StickyEventError extends StickyEventType {
  type = STICKY_EVENT_TYPE_ERROR;
}

export class StickyEventComponent implements StickyEventInterface {
  type = STICKY_EVENT_TYPE_COMPONENT;
  actions: StickyEventActionInterface;
  autoHide: number | boolean;
  component: Type<any>;
  context: string;

  constructor(
    // eslint-disable-next-line max-len
    {component, actions, autoHide, context}: { component?: Type<any>, actions?: StickyEventActionInterface, autoHide?: number | boolean, context?: string }
  ) {
    this.component = component;
    this.actions = actions;
    this.autoHide = autoHide;
    this.context = context;
  }
}

export type StickyEvent = StickyEventSuccess | StickyEventWarning | StickyEventError | StickyEventComponent;
