import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {merge, SessionState, SessionStateModel} from '@myvf/core';
import {TealiumModel, TealiumModelFlat} from './tealium.model';
import {LoggerService} from '../../../core/common/logger.service';
import {WindowService} from '../../../core/common/window.service';
import {TealiumModule} from './tealium.module';
import {MD5, SHA256} from 'crypto-js';

@Injectable({
  providedIn: TealiumModule
})
export class TealiumAnalyticsService {
  constructor(
    private logger: LoggerService,
    private router: Router,
    private store: Store,
    private window: WindowService,
  ) {
  }

  get params(): TealiumModel {
    const {
      appVersion, appPlatform, osVersion,
      user, value, status,
      type_sim, type, auth, androidServices,
      customerId, items, visitor_state_multi_factor
    }: SessionStateModel = this.store.selectSnapshot(SessionState);

    const subscriptionType =
      type === 'sim' && type_sim === 'ricaricabile' ? 'Payg' :
        type === 'sim' && type_sim === 'abbonamento' ? 'Paym' :
          type === 'landline' ? 'Fixed' :
            type === 'contract' ? 'Contract' : null;

    const userStatus =
      status === 'authenticated' ? 'Logged in' :
        status === 'enriched' ? 'Enriched' :
          'Logged out';

    const visitorIdAssetList: string[] = (items && items.length>0) ? items.map((item)=>SHA256(item.value).toString()) : null;

    const visitorIdAssetPrimary: string =
      (items && items.length>0) ? items.find(e => e.type === 'sim') ? items.find(e => e.type === 'sim').value :
        items[0].value ? items[0].value : value : value;

    const visitorAssetTypeActive = type === 'sim' ? 'Mobile' :  type === 'landline' ? 'Fixed' : null ;

    return {
      'page': {
        'pageInfo': {
          'destinationURL': this.window.native.location.origin + this.router.routerState.snapshot.url,
          'sysEnv': 'App',
          'language': 'it-IT',
          'georegion': 'IT'
        },
        'category': {
          'primaryCategory': 'Consumer',
          'subCategory1': 'Self Service',
          'subCategory2': 'Info'
        },
        'attributes': {
          'querystring': this.router.routerState.snapshot.root.queryParams
        }
      },
      'account': {
        'profileInfo': {
          'username': user && user.username,
          'persistentBO': auth && auth.pbo,
          'profileID': MD5(value).toString(),
          'MSISDN': SHA256(value).toString(),
          'market': 'Consumer',
          'status': userStatus,
          'subscriptionType': subscriptionType,
          'version': user && user.isNext && user.isNext ? 'Next' : ''
        }
      },
      'user': {
        'profileInfo': {
          'username': user && user.username,
          'persistentBO': auth && auth.pbo,
          'profileID': MD5(value).toString(),
          'MSISDN': SHA256(value).toString(),
          'market': 'Consumer',
          'status': userStatus,
          'subscriptionType': subscriptionType,
          'version': user && user.isNext && user.isNext ? 'Next' : ''
        }
      },
      'vendor': {
        'adobe': {
          'MCID': auth && auth.mid,
          'reportSuite': 'app'
        }
      },
      'device': {
        'operatingSystem': {
          'name': appPlatform,
          'version': osVersion
        },
        'app': {
          'name': 'My Vodafone',
          'version': appVersion,
          'uuid': auth && auth.installation_id
        },
        'androidServices': androidServices
      },
      'visitor_id_asset_active': SHA256(value).toString(),
      ...visitorIdAssetList && {'visitor_id_asset_list': visitorIdAssetList},
      ...visitorIdAssetPrimary && {'visitor_id_asset_primary': SHA256(visitorIdAssetPrimary).toString()},
      ...customerId && {'visitor_id_customer': customerId},
      ...visitorAssetTypeActive && {'visitor_asset_type_active': visitorAssetTypeActive},
      'visitor_login_status': userStatus.toLowerCase(),
      customer_centric_page: false,
      ...visitor_state_multi_factor && {'visitor_state_multi_factor': visitor_state_multi_factor}
    };
  }

  get paramsFlat(): TealiumModelFlat {
    const {
      appVersion, appPlatform, osVersion,
      user, value, status,
      type_sim, type, auth, androidServices,
      customerId, items, visitor_state_multi_factor
    }: SessionStateModel = this.store.selectSnapshot(SessionState);

    const subscriptionType =
      type === 'sim' && type_sim === 'ricaricabile' ? 'Payg' :
        type === 'sim' && type_sim === 'abbonamento' ? 'Paym' :
          type === 'landline' ? 'Fixed' :
            type === 'contract' ? 'Contract' : null;

    const userStatus =
      status === 'authenticated' ? 'Logged in' :
        status === 'enriched' ? 'Enriched' :
          'Logged out';

    const visitorIdAssetList: string[] = (items && items.length>0) ? items.map((item)=>SHA256(item.value).toString()) : null;

    const visitorIdAssetPrimary: string =
      (items && items.length>0) ? items.find(e => e.type === 'sim') ? items.find(e => e.type === 'sim').value :
        items[0].value ? items[0].value : value : value;

    const visitorAssetTypeActive = type === 'sim' ? 'Mobile' :  type === 'landline' ? 'Fixed' : null ;

    return {
      event_category: 'journey',
      journey_type: 'journey',
      page_channel: 'consumer',
      page_environment: 'app',
      page_browser_system: 'webview',
      page_domain: 'vodafone.it',
      page_language: 'IT',
      page_locale: 'it-IT',
      page_url: this.window.native.location.origin + this.router.routerState.snapshot.url,
      visitor_login_status: userStatus.toLowerCase(),
      visitor_id_backend_stack: user && user.isNext && user.isNext ? 'Next' : '',
      visitor_id_asset_active: SHA256(value).toString(),
      ...visitorIdAssetPrimary && {'visitor_id_asset_primary': SHA256(visitorIdAssetPrimary).toString()},
      visitor_id_asset_active_md5: MD5(value).toString(),
      ...visitorIdAssetList && {'visitor_id_asset_list': visitorIdAssetList},
      ...visitorAssetTypeActive && {'visitor_asset_type_active': visitorAssetTypeActive},
      ...visitor_state_multi_factor && {'visitor_state_multi_factor': visitor_state_multi_factor},
      app_name: 'My Vodafone',
      app_version: appVersion,
      app_id: `My Vodafone ${appVersion}`,
      adobe_MCID: auth && auth.mid,
      user_version: user && user.isNext && user.isNext ? 'Next' : '',
    };
  }

  /**
   * Questo metodo conterrà il bridge con la libreria di Adobe
   */
  push(action: string, obj: TealiumModel): TealiumModel {

    const digitalData = merge([this.params, obj]);

    this.logger.group('Tealium Tagging', digitalData, JSON.stringify(digitalData));

    if (this.window.native['wa_gc']) {
      this.window.native['wa_gc'](digitalData, action === 'click' ? 'link' : action);
    }

    return digitalData;
  }

  /**
 * Questo metodo conterrà il bridge con la libreria di Adobe
 */
  pushFlat(action: string, obj: TealiumModel): TealiumModel {

    const digitalData = merge([this.paramsFlat, obj]);

    this.logger.group('Tealium Tagging', digitalData, JSON.stringify(digitalData));

    if (this.window.native['wa_gc']) {
      this.window.native['wa_gc'](digitalData, action === 'click' ? 'link' : action);
    }

    return digitalData;
  }
}
