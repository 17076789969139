import {NgModule} from '@angular/core';
import {LinkModule} from './link';
import {LoaderModule} from './loader';
import {NotificationModule} from './notification';
import {PipesModule} from './pipes';
import {StickyModule} from './sticky';
import {TaggingModule} from './tagging';
import {OperativeMessageModule} from './operative-message';
import {OneTimePasswordModule} from './one-time-password';
import {MoneyManagementFormModule} from './money-management-form';
import {CustomErrorModule} from './errors/custom-error.module';

@NgModule({
  imports: [
    LinkModule,
    LoaderModule,
    NotificationModule,
    PipesModule,
    StickyModule,
    TaggingModule,
    OperativeMessageModule,
    OneTimePasswordModule,
    CustomErrorModule,
    MoneyManagementFormModule
  ],
  exports: [
    LinkModule,
    LoaderModule,
    NotificationModule,
    PipesModule,
    StickyModule,
    TaggingModule,
    OperativeMessageModule,
    OneTimePasswordModule,
    CustomErrorModule,
    MoneyManagementFormModule
  ]
})
export class MyvfSharedModule {
}
