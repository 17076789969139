import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoneyManagementFormComponent } from './money-management-form/money-management-form.component';

@NgModule({
  declarations: [
    MoneyManagementFormComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MoneyManagementFormComponent
  ]
})
export class MoneyManagementFormModule { }
