import {Inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggerService} from '../common/logger.service';
import {SessionState, SessionStateModel} from '../session';
import {Store} from '@ngxs/store';
import {CORE_CONFIGURATION} from '../configuration';

@Injectable({
  providedIn: 'root',
  deps: [
    Store,
    Router,
    LoggerService,
    CORE_CONFIGURATION
  ]
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private logger: LoggerService,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.config.isInEditor) {
      return true;
    }

    const {status, customerId}: SessionStateModel = this.store.selectSnapshot(SessionState);
    this.logger.group(
      `Auth Guard: Status(${status} CustomerId(${customerId})`
    );

    if (status || customerId) {
      return true;
    }

    this.router.navigate([this.config.errorPageAccessDenied]);

    return false;
  }
}
