import {Component} from '@angular/core';
import {BridgeService, NotificationService, SessionState, WindowService} from '@myvf/core';
import {filter} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {NativeAchorEnum} from '../../native-anchor';
import {TealiumAnalyticsService} from '../../tagging/tealium/tealium-analytics.service';

@Component({
  selector: 'myvf-notification',
  template: `
    <myvf-ui-alert
      [class.show]="isShow"
      [title]="'Info'"
      (close)="handleClose()"
      (retry)="handleRetry()">{{ message }}
    </myvf-ui-alert>
  `
})
export class NotificationComponent {
  message = 'Servizio non disponibile';
  isShow = false;

  constructor(
    private service: NotificationService,
    private win: WindowService,
    private bridge: BridgeService,
    private store: Store,
    private tealium: TealiumAnalyticsService
  ) {
    this.service
      .notification$
      .pipe(
        filter(n => n !== null)
      )
      .subscribe(data => {
        this.isShow = true;
        this.message = data || this.message;
        this.tagging();
      });
  }

  handleClose() {
    const {isOverlay} = this.store.selectSnapshot(SessionState);

    if (isOverlay) {
      this.bridge.closeOverlay({});
    } else {
      this.win.go(NativeAchorEnum.HOMEPAGE);
    }
  }

  handleRetry() {
    this.win.reload();
  }

  tagging() {
    this.tealium.push('view', {
      page: {
        pageInfo: {
          pageName: 'MyVodafone:Error'
        },
        category: {
          subCategory1: 'Error'
        },
        attributes: {
          pageError: this.message,
          errorStatus: `${this.message}`
        }
      },
      event: [{
        eventInfo: {eventName: 'page_error'},
        category: {eventName: 'error'}
      }]
    });
  }
}

