import {Inject, Injectable, Injector, Type} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Router, NavigationError} from '@angular/router';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LoggerService} from '../common/logger.service';
import {CORE_CONFIGURATION} from '../configuration';
import {ErrorsServicesModule} from './errors-services.module';

import * as StackTraceParser from 'error-stack-parser';
import {BaseCustomErrorResponse} from '../api/custom-error-response.type';

@Injectable({
  providedIn: ErrorsServicesModule,
  deps: [
    Injector,
    Router,
    CORE_CONFIGURATION
  ]
})
export class ErrorsService {
  constructor(
    private injector: Injector,
    private router: Router,
    @Inject(CORE_CONFIGURATION) private config
  ) {
    this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationError)
      )
      .subscribe((event: NavigationError) => {
        this.log(event.error)
          .subscribe(() => {
            this.router.navigate([this.config.errorPageNotFound]);
          });
      });
  }

  log(error) {
    const logger = this.injector.get(LoggerService);
    const errorToSend = this.addContextInfo(error);

    logger.group(`Error Handler: ${error.name}`, errorToSend);

    return of(errorToSend);
  }

  addContextInfo(error) {
    const name = error.name || null;
    const appId = 'myvf-consumer-fe';
    const time = new Date().getTime();
    const id = `${appId}-${time}`;
    const location = this.injector.get<LocationStrategy>(LocationStrategy as unknown as Type<LocationStrategy>);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse || error instanceof BaseCustomErrorResponse ? null : StackTraceParser.parse(error);

    return {name, appId, time, id, url, status, message, stack};
  }

}
