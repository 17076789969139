import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {OneTimePasswordEvent} from './one-time-password-event.type';

export interface OneTimePasswordServiceEvent {
  show: boolean;
  event?: OneTimePasswordEvent;
}

/**
 * service for manage otp component
 */
@Injectable({
  providedIn: 'root'
})
export class OneTimePasswordService {
  public events: Observable<OneTimePasswordServiceEvent> = new Subject<OneTimePasswordServiceEvent>();

  constructor() {
  }

  /**
   * open otp compoenent
   * @param event data for otp provided by parent component
   */
  public open(event: OneTimePasswordEvent): void {
    (this.events as Subject<OneTimePasswordServiceEvent>).next({show: true, event});
  }

  /**
   * close otp compoenent
   */
  public close(): void {
    (this.events as Subject<OneTimePasswordServiceEvent>).next({show: false});
  }
}


