import {Component, OnInit} from '@angular/core';
import {CustomErrorService} from './custom-error.service';
import {AemGetMessages} from '../../../aem/shared/aem.action';
import {filter} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AemState} from '@myvf/aem';
import {TealiumAnalyticsService} from '../../tagging/tealium/tealium-analytics.service';
import {SessionState} from '@myvf/core';

@Component({
  selector: 'myvf-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent implements OnInit {

  public show = false;
  public customCode: number;
  public customMessage: string;
  public imageUrl: string;
  public aemPath: string;

  constructor(
    public customErrorService: CustomErrorService,
    public store: Store,
    private tealium: TealiumAnalyticsService
  ) {
  }

  ngOnInit() {
    const {user: {isNext}} = this.store.selectSnapshot(SessionState);
    const showAemCustomError = (show: boolean, customCode: number, customErrors: Array<any>) => {
      this.show = show;
      this.customCode = customCode;
      this.customMessage = customErrors.find(d => d.code === customCode) ?
        customErrors.find(d => d.code === customCode).message : 'Si è verificato un errore';
      this.imageUrl = customErrors.find(d => d.code === customCode) ?
        customErrors.find(d => d.code === customCode).imageUrl : null;
      this.tagging();
    };

    this.aemPath = (!isNext) ?
      'content/myvf/configurations/messages.contents.json' : 'content/myvf/configurations/next-messages.contents.json';
    this.customErrorService
      .events
      .pipe(filter(({show}) => show))
      .subscribe(({show, customCode}) => {
        const {messages} = this.store.selectSnapshot(AemState);

        if (messages) {
          showAemCustomError(show, customCode, messages.customErrors);
        } else {
          this.store
            .dispatch(new AemGetMessages(this.aemPath))
            .subscribe(({aem: {messages: {customErrors}}}) => {
              showAemCustomError(show, customCode, customErrors);
            });
        }
      });
  }

  tagging() {
    this.tealium.push('view', {
      page: {
        pageInfo: {
          pageName: 'MyVodafone:Error'
        },
        category: {
          subCategory1: 'Error'
        },
        attributes: {
          pageError: this.customMessage,
          errorStatus: `${this.customCode}|${this.customMessage}`
        }
      },
      event: [{
        eventInfo: {eventName: 'page_error'},
        category: {eventName: 'error'}
      }]
    });
  }
}
