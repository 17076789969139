import {all} from 'deepmerge';

export function omit(obj, arr) {
  return Object.keys(obj)
    .filter(k => !arr.includes(k))
    .reduce((acc, key) => {
      acc[key] = obj[key];

      return acc;
    }, {});
}

export function pick(obj, arr) {
  return arr.reduce((acc, curr) => {
    if (curr in obj) {
      acc[curr] = obj[curr];
    }

    return acc;
  }, {});
}

export function is(type: any, val: any): boolean {
  return ![undefined, null].includes(val) && val.constructor === type;
}

export function delay(fn, wait, ...args): void {
  const int = setTimeout(() => {
    clearTimeout(int);
    fn(...args);
  }, wait);
}

export function trimStart(str: string, chars: string = ' '): string {
  const reg = new RegExp(`^${chars}`);

  return str.replace(reg, '');
}

export function trimEnd(str: string, chars: string = ' '): string {
  const reg = new RegExp(`${chars}$`);

  return str.replace(reg, '');
}

export function trim(str: string, chars: string = ' '): string {
  return trimStart(trimEnd(str, chars), chars);
}

export function toCamelCase(str) {
  const s =
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('');

  return s.slice(0, 1).toLowerCase() + s.slice(1);
}

export function encodeCookie(obj: object): string {
  return Object.keys(obj).map(key => (typeof obj[key] === 'object') ? `${key}=${JSON.stringify(obj[key])}` : `${key}=${obj[key]}`).join('|');
}

export function decodeCookie(str: string): object {
  return (String(str).toString())
    .split('|')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      (key === 'isNext') ?
        acc[key] = (value === 'true') :
        (key === 'items') ?
          acc[key] = JSON.parse(value) :
          acc[key] = value;

      return acc;
    }, {});
}

export function decodeResponseBridge(param: string | object): object {
  if (typeof param === 'string') {
    try {
      return JSON.parse(param);
    } catch (e) {
      return JSON.parse(param.replace(/"{"/g, '{"').replace(/}}"/g, '}}'));
    }
  }

  return param;
}

export function renameProperty(obj: Record<string, any>, oldPropertyName: string, newPropertyName: string): Record<string, any> {
  if (!obj.hasOwnProperty(oldPropertyName)) {
    return obj;
  }

  const { [oldPropertyName]: _, ...rest } = obj;
  return { ...rest, [newPropertyName]: obj[oldPropertyName] };
}

export function keysInObject(keys: Array<string>, obj: object) {
  let found = false;

  keys.forEach(k => {
    if (k in obj) {
      obj = obj[k];
      found = true;
    } else {
      found = false;
    }
  });

  return found;
}

export function create_UUID() {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);

    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function snakeToCamelCase(str: string): string {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function getFinalResponseData() {
  return (response) => {
    return response.hasOwnProperty('result') ? response.result : response;
  };
}

export function deepClone(ref) {
  return JSON.parse(JSON.stringify(ref));
}

export const merge = all;

export function flatDeep(arr, d = 1) {
  return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), []) : arr.slice();
}
