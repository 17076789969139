import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {ApiModule} from './api.module';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class AppApiService {
  constructor(
    private http: BaseApiService
  ) {
  }

  start(params: any): Observable<any> {
    return this.http.post('/api/v3/app/start', params);
  }

  sim(number: string): Observable<any> {
    return this.http.get(`/api/v3/app/sim/${number}`);
  }
}
