import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AemState} from '@myvf/aem';
import {keysInObject, SessionState, trim} from '@myvf/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/it';

dayjs.extend(customParseFormat);

@Injectable({
  providedIn: 'root'
})
export class OperativeMessageService {

  constructor(private store: Store) {
  }

  public confirmActivationMessages(productName: string) {
    const {messages: {activation: messages}} = this.store.selectSnapshot(AemState);
    const title = messages.confirm_activation.title;
    const body = messages.confirm_activation.description.replace('@prodName@', productName);

    return {title, body};
  }

  public confirmActivationIServiceMessages(activationDate: string, productName = '') {
    const {messages: {activation: messages}} = this.store.selectSnapshot(AemState);
    const {title, primaryCtaLabel, dismissCtaLabel, icon} = messages.confirm_activation_iservice;
    const description = messages.confirm_activation_iservice.description.replace('@prodName@', productName).replace('@activationDate@', dayjs(activationDate, 'YYYY/MM/DD').locale('it').format('DD/MM/YYYY'));

    return {title, description, primaryCtaLabel, dismissCtaLabel, icon};
  }

  public checkActivationMessages(
    {
      incompatibilityType: incType,
      incompatibilities,
      activationParams,
      retryable,
      hasCredit
    }: any
  ) {
    const {messages: {activation: messages}} = this.store.selectSnapshot(AemState);

    const title = {params: '', strong: '', weak: '', credit: ''};
    const body = {params: '', strong: '', weak: '', credit: ''};

    const isStrongChargeWait = () => incType && incType.toLowerCase() === 'STRONG' &&
      incompatibilities.find(d => d.type.toLowerCase() === 'charge_wait');

    // Activation Params messages
    if (activationParams && activationParams.length) {
      title.params = messages.activation_params.title;
      body.params = `${messages.activation_params.description}`;
    }

    // Incompatibilities messages
    incType = incType.toLowerCase();

    incompatibilities && incompatibilities.forEach(inc => {
      const dtlIncType = inc.type.toLowerCase();

      title[incType] = keysInObject([incType, dtlIncType], messages) ? messages[incType][dtlIncType].title : title[incType];

      if (inc.type.toLowerCase() !== 'errors') {
        body[incType] += keysInObject([incType, dtlIncType], messages) ? `${messages[incType][dtlIncType].description}<br>` : '';

        inc.products && inc.products.forEach((prod) => {
          body[incType] += `${prod.name}<br>`;
        });
      } else {
        inc.errors.forEach(err => {
          const errType = err.type.toLowerCase();

          body[incType] += keysInObject([incType, dtlIncType, errType], messages) ? messages[incType][dtlIncType][errType] : '';
        });
      }
    });

    if (!hasCredit && retryable.toLowerCase() === 'n') {
      if (isStrongChargeWait() || incType.toLowerCase() !== 'strong') {
        title.credit = messages.not_credit.title;
        body.credit = messages.not_credit.description;
      } else {
        title.credit = title.strong;
        body.credit = `${body.strong}<br>${messages.not_credit.description}`;
      }
    }

    return {
      title: title.credit ? title.credit : title.strong ? title.strong : title.weak ? title.weak : title.params,
      body: body.credit ? body.credit : body.strong ? body.strong : trim(`${body.weak} ${body.params}`)
    };
  }

  public performActivationMessages({email_address, email_status, nbaMultiproduct}: any) {
    const {messages: {activation: messages}} = this.store.selectSnapshot(AemState);
    const {user: {email}} = this.store.selectSnapshot(SessionState);

    email_address = email_address ? email_address : email;

    let title = '';
    let body = '';

    if( nbaMultiproduct === true ) {
      title = messages.success_activation_request_nba_informative.title;
      body = messages.success_activation_request_nba_informative.description;
    } else if (email_status === 'SENT') {
      title = messages.success_activation_mail_sent.title;
      body = messages.success_activation_mail_sent.description.replace('@emailAddress@', email_address);
    } else if (email_status === 'TO_SEND') {
      title = messages.success_activation_mail_to_send.title;
      body = messages.success_activation_mail_to_send.description;
    } else {
      title = messages.success_activation.title;
      body = messages.success_activation.description;
    }

    return {title, body};
  }

  public performActivationNextMessages() {
    const {messages: {activation: messages}} = this.store.selectSnapshot(AemState);
    const title = messages.success_activation.title;
    const body = messages.success_activation.description;

    return {title, body};
  }

  public performActivationNextIServiceMessages(product, name) {
    const {messages: {activation: messages}} = this.store.selectSnapshot(AemState);
    const title = messages.success_activation_iservice.title.replace('@prodName@', name);
    const {description, primaryCtaLabel, dismissCtaLabel, icon} = messages.success_activation_iservice;

    return {title, description, primaryCtaLabel, dismissCtaLabel, icon};
  }

  public mailSentActivationMessage(emailAddress: string) {
    const {messages: {activation: {success_activation_mail_sent: {title, description}}}} = this.store.selectSnapshot(AemState);
    const body = description.replace('@emailAddress@', emailAddress);

    return {title, body};
  }

  public confirmDeactivationMessages(data: any) {
    let title: string;
    let body: string;

    if (data.messageDeactivationConfirmTitle || data.messageDeactivationConfirmDescription) {
      title = data.messageDeactivationConfirmTitle;
      body = data.messageDeactivationConfirmDescription.replace('@prodName@', data.offerTitle);
    } else {
      const {messages: {deactivation: messages}} = this.store.selectSnapshot(AemState);
      title = messages.confirm_deactivation.title;
      body = messages.confirm_deactivation.description.replace('@prodName@', data.offerTitle);
    }

    return {title, body};
  }

  public checkDeactivationMessage({block_type, errors, blocking_products, deactivation_date}) {
    const {messages: {deactivation: messages}} = this.store.selectSnapshot(AemState);

    let title = '';
    let body = '';

    if (block_type && block_type.toLowerCase() === 'errors') {
      title = messages.errors.error.title;

      errors && errors.forEach(v => {
        body += messages.errors[v.type.toLowerCase()] ? `${messages.errors[v.type.toLowerCase()]}<br>` : '';
      });

      blocking_products && blocking_products.forEach(v => {
        body += `${v.name}<br>`;
      });

      if (!body) {
        body = messages.errors.error.description;
      }

    } else if (block_type && messages[block_type.toLowerCase()]) {
      title = messages[block_type.toLowerCase()].title;
      body = messages[block_type.toLowerCase()].description;
    }

    body = deactivation_date ? body.replace('@deactivationDate@',
      dayjs(deactivation_date, 'DD/MM/YYYY').locale('it').format('DD MMMM YYYY')) :
      body.replace('@deactivationDate@', '');

    return {title, body};
  }

  public performDeactivationMessage({email_address, email_status}: any) {
    const {messages: {deactivation: messages}} = this.store.selectSnapshot(AemState);
    const {user: {email}} = this.store.selectSnapshot(SessionState);

    email_address = email_address ? email_address : email;

    let title = '';
    let body = '';

    if (email_status === 'SENT') {
      title = messages.success_deactivation_mail_sent.title;
      body = messages.success_deactivation_mail_sent.description.replace('@emailAddress@', email_address);
    } else if (email_status === 'TO_SEND') {
      title = messages.success_deactivation_mail_to_send.title;
      body = messages.success_deactivation_mail_to_send.description;
    } else {
      title = messages.success_deactivation.title;
      body = messages.success_deactivation.description;
    }

    return {title, body};
  }

  public mailSentDeactivationMessage(emailAddress: string) {
    const {messages: {deactivation: {success_deactivation_mail_sent: {title, description}}}} = this.store.selectSnapshot(AemState);
    const body = description.replace('@emailAddress@', emailAddress);

    return {title, body};
  }

  public confirmRenewalMessages(productName: string, renewalCost: string) {
    const {messages: {renewal: messages}} = this.store.selectSnapshot(AemState);
    const title = messages.confirm_renewal.title;
    const body = messages.confirm_renewal.description.replace('@prodName@', productName).replace('@renewalCost@', renewalCost);

    return {title, body};
  }

  public checkRenewalMessage({block_type}) {
    const {messages: {renewal: messages}} = this.store.selectSnapshot(AemState);

    const title = messages.checkBlockType.title;
    const body = messages.checkBlockType[block_type.toLowerCase()];

    return {title, body};
  }

  public performRenewalMessage({email, mail_status}: any) {
    const {messages: {renewal: messages}} = this.store.selectSnapshot(AemState);
    const {user: {email: emailUser}} = this.store.selectSnapshot(SessionState);

    email = email ? email : emailUser;

    let title = '';
    let body = '';

    if (mail_status === 'SENT') {
      title = messages.success_renewal_mail_sent.title;
      body = messages.success_renewal_mail_sent.description.replace('@emailAddress@', email);
    } else if (mail_status === 'TO_SEND') {
      title = messages.success_renewal_mail_to_send.title;
      body = messages.success_renewal_mail_to_send.description;
    } else {
      title = messages.success_renewal.title;
      body = messages.success_renewal.description;
    }

    return {title, body};
  }

  public mailSentRenewalMessage(emailAddress: string) {
    const {messages: {renewal: {success_renewal_mail_sent: {title, description}}}} = this.store.selectSnapshot(AemState);
    const body = description.replace('@emailAddress@', emailAddress);

    return {title, body};
  }

  /**
   * NEXT
   */
  public deactivationMessages(data: any) {
    let title: string;
    let body: string;

    if (data.messageDeactivationConfirmTitle || data.messageDeactivationConfirmDescription) {
      title = data.messageDeactivationConfirmTitle;
      body = data.messageDeactivationConfirmDescription.replace('@prodName@', data.product.name);
    } else {
      const {messages: {deactivation: messages}} = this.store.selectSnapshot(AemState);
      title = messages.confirm_deactivation.title;
      body = messages.confirm_deactivation.description.replace('@prodName@', data.offerTitle);
    }

    return {title, body};
  }

  public observeDeactivationMessage() {
    const {messages: {deactivation: messages}} = this.store.selectSnapshot(AemState);

    return {
      title: messages.success_deactivation.title,
      body: messages.success_deactivation.description
    };
  }
}
