import {Component, NgZone} from '@angular/core';
import {LoaderService} from './loader.service';

@Component({
  selector: 'myvf-loader',
  template: '<myvf-ui-spinner [show]="visible"></myvf-ui-spinner>'
})
export class LoaderComponent {
  public visible: boolean;

  constructor(
    private service: LoaderService,
    private ngzone: NgZone
  ) {
    service
      .spinner
      .subscribe(data =>
        ngzone.run(() => {
          this.visible = data;
        })
      );
  }
}
