export interface OneTimePasswordEventInterface {
  taggingInfo: object;
  successFunction: Function;
  undoFunction?: Function;
}

export class OneTimePasswordEvent implements OneTimePasswordEventInterface {
  taggingInfo: object;
  successFunction: Function;
  undoFunction: Function;

  constructor({taggingInfo, successFunction, undoFunction = null}: OneTimePasswordEventInterface) {
    this.successFunction = successFunction;
    this.undoFunction = undoFunction;
    this.taggingInfo = taggingInfo;
  }
}
