import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionComponent} from './accordion/accordion.component';
import {AccordionHeadComponent} from './accordion/accordion-head.component';
import {AccordionBodyComponent} from './accordion/accordion-body.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AccordionComponent,
    AccordionHeadComponent,
    AccordionBodyComponent
  ],
  exports: [
    AccordionComponent,
    AccordionHeadComponent,
    AccordionBodyComponent
  ]
})
export class AccordionModule {
}
