import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {ApiModule} from './api.module';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class SessionApiService {
  constructor(
    private http: BaseApiService
  ) {
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post('/api/v1/session/login', {username, password});
  }
}
