import {Item} from '../session';

export class AwsLogin {
  static readonly type = '[AwsAuthorization] AwsLogin';

  constructor(public params: { username: string, password: string }, public register: any) {
  }
}

export class AwsDevice {
  static readonly type = '[AwsAuthorization] AwsDevice';

  constructor(public params: any) {
  }
}

export class AwsSwitchItem {
  static readonly type = '[AwsAuthorization] AwsSwitchItem';

  constructor(public item: Item) {
  }
}
