import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorsService} from './errors.service';
import {LoggerService} from '../common/logger.service';
import {WindowService} from '../common/window.service';
import {NotificationService} from '../notification';
import {
  CustomErrorPageResponse,
  CustomErrorResponse,
  MaintenanceErrorResponse,
  SessionExpiredErrorResponse
} from '../api/custom-error-response.type';
import {BridgeService} from '../bridge';
import {CustomErrorService} from '../../shared/errors/custom-error/custom-error.service';

@Injectable()
export class ErrorsHandlerService implements ErrorHandler {
  constructor(
    private injector: Injector
  ) {
  }

  handleError(error: Error | HttpErrorResponse | CustomErrorResponse) {
    const window = this.injector.get(WindowService);
    const errorService = this.injector.get(ErrorsService);
    const logger = this.injector.get(LoggerService);
    const notification = this.injector.get(NotificationService);
    const customError = this.injector.get(CustomErrorService);
    const bridge = this.injector.get(BridgeService);

    errorService.log(error).subscribe();

    if (error instanceof HttpErrorResponse) {
      if (error.status === 403 || error.status === 401) {
        logger.group('Error Handler: HttpErrorResponse', 'Session Expired');
        return bridge.restartFlowPopup('Session Expired');
      }

      if (!window.native.navigator.onLine) {
        logger.group('Error Handler: HttpErrorResponse', 'No Internet Connection');

        return notification.notify('No Internet Connection');
      }

      if (!error.url.includes('hideError=true') && error.status !== 503) {
        return notification.notify(error.error?.message || '');
      }
    } else if (error instanceof CustomErrorResponse || error instanceof MaintenanceErrorResponse) {
      return notification.notify(error.message);

    } else if (error instanceof SessionExpiredErrorResponse) {
      return bridge.restartFlowPopup('Session Expired');

    } else if (error instanceof CustomErrorPageResponse) {
      return customError.open(error.code);
    }
  }
}
