export abstract class BaseCustomErrorResponse {
  public code: number;
  public message: string;
  public name: string;

  constructor(init: { code?: number, message?: string } = {}) {
    this.code = init.code;
    this.message = init.message;
  }
}

export class CustomErrorResponse extends BaseCustomErrorResponse {
}

export class SessionExpiredErrorResponse extends BaseCustomErrorResponse {
}

export class MaintenanceErrorResponse extends BaseCustomErrorResponse {
}

export class CustomErrorPageResponse extends BaseCustomErrorResponse {
}
