import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BridgeManagerService} from './bridge-manager.service';
import {BridgeApiService} from './bridge-api.service';
import {BridgeApiEvent, ShowDrawerModel, showOverlayAdditionalParams} from './bridge.model';

@Injectable({
  providedIn: 'root',
  deps: [
    BridgeManagerService,
    BridgeApiService
  ]
})
export class BridgeService {
  constructor(
    protected manager: BridgeManagerService,
    private api: BridgeApiService
  ) {
  }

  get events(): Observable<BridgeApiEvent> {
    return this.api.events;
  }

  isReady(): boolean {
    return this.manager.isReady;
  }

  isWebView(): boolean {
    return this.manager.isWebView();
  }

  /**
   * Setta i dati dell'utente e della sim
   */
  getSession(): Promise<any> {
    return this.manager.call('getSession', {});
  }

  /**
   * Apre lo sticky feed su base id.
   */
  sticky(id: string): Promise<any> {
    return this.manager.call('sticky', {id});
  }

  /**
   * Apre link sul browser esterno.
   */
  openInBrowser(url: string): Promise<any> {
    return this.manager.call('openInBrowser', {url});
  }

  /**
   * Funzione richiamata dal Client verso il FE con parametro “true”
   *
   * @deprecated
   */
  isOpenInBrowserSupported(): Promise<any> {
    return this.manager.call('isOpenInBrowserSupported', {});
  }

  /**
   * Abilita il pinch per lo zoom.
   *
   */
  setPinchToZoomEnabled(enabled: boolean): Promise<any> {
    return this.manager.call('setPinchToZoomEnabled', {enabled});
  }

  /**
   * Imposta la url da richiamare alla pressione del tasto back.
   */
  setBackButtonUrl(url: string): Promise<any> {
    return this.manager.call('setBackButtonUrl', {url});
  }

  /**
   * Usata per chiudere la Splash animata.
   * ANDROID: funzione presente solo sul bridge della webview dello splash
   *
   * @deprecated
   */
  closeWebView(): Promise<any> {
    return this.manager.call('closeWebView', {});
  }

  /**
   * Apre SpeedTest nativo.
   *
   * @deprecated
   */
  openSpeedTest(): Promise<any> {
    return this.manager.call('openSpeedTest', {});
  }

  /**
   * Apre applicazioni esterne, provando prima con l’url scheme, poi con il link dello store, e tagga tramite Omniture la pagina.
   * ANDROID: non usa 'internalLink' ne 'pageNameTag'
   *
   * @deprecated
   */
  openExternalApp(params: { internalLink: string, externalLink: string, pageNameTag: string }): Promise<any> {
    return this.manager.call('openExternalApp', params);
  }

  /**
   * Mostra popup con titolo “Info”, messaggio preso dal parametro “message” della funzione e bottone “Chiudi”
   * ANDROID: testo bottone 'Ok'
   */
  showErrorMessage(message: string): Promise<any> {
    return this.manager.call('showErrorMessage', {message});
  }

  /**
   * sendMail
   *
   * @deprecated
   */
  sendMail(params: { object: string, recipient: string, text: string }): Promise<any> {
    return this.manager.call('sendMail', params);
  }

  /**
   * Mostra popup con titolo “Info”, messaggio preso dal parametro “message” della funzione e bottone “Riprova”,
   * che scatena il riavvio dell’applicazione.
   * ANDROID: titolo 'Info' messaggio 'Servizio non disponibile' bottoni 'Riprova' , 'Chiudi'
   */
  restartFlowPopup(message: string): Promise<any> {
    return this.manager.call('restartFlowPopup', {message});
  }

  /**
   * Scatena l’azione ricevuta dalla funzione; i parametri ricevuti saranno quello di una ​VFAction
   */
  doAction(params: { type: string, native_id?: string, web_url?: string }): Promise<any> {
    return this.manager.call('doAction', params);
  }

  /**
   * Traccia la pagina su Omniture prendendo il parametro “pageNameTag”
   * ANDROID: comando eliminato con enhancement
   *
   * @deprecated
   */
  tagOmniture(pageNameTag: string): Promise<any> {
    return this.manager.call('tagOmniture', {pageNameTag});
  }

  /**
   * Prende il parametro “tagName” che si tiene da parte su Omniture, senza lanciare veramente il tracciamento.
   *
   * @deprecated
   */
  setPageName(tagName: string): Promise<any> {
    return this.manager.call('setPageName', {tagName});
  }

  /**
   * Notifica se abilitare o meno lo shake e, se il parametro “enabled” è true, avvia l'accelerometro per rilevare gli eventi di shake.
   */
  shakeEnable(enabled: boolean): Promise<any> {
    return this.manager.call('shakeEnable', {enabled});
  }

  /**
   * Notifica che l’utente ha finito di shakerare.
   */
  shakeFinished(): Promise<any> {
    return this.manager.call('shakeFinished', {}, 'shakeFinishedCallBack');
  }

  /**
   * Processa l’attivazione del prodotto IMC.
   */
  productActivated(params: { msisdn: string, keyCode: string, cycle: string, campaignCode: string, pushType: string }): Promise<any> {
    return this.manager.call('productActivated', params);
  }

  /**
   * Cancella una push dal centro notifiche dell’app.
   * Disponibile da AaaP (10.0.0).
   * Il match viene effettuato confrontando le stringhe in modalità case sensitive.
   */
  deletePush(params: { pushId: string, pushFlow: string }): Promise<any> {
    return this.manager.call('deletePush', params);
  }

  /**
   * Abilita o meno la gesture dello swipe per il back.
   * ANDROID: comando assente
   *
   * @deprecated
   */
  setSwipeEnabled(enabled: boolean): Promise<any> {
    return this.manager.call('setSwipeEnabled', {enabled});
  }

  /**
   * Chiede di caricare la pagina ibrida con o senza autenticazione biometrica.
   * - biometrics: (booleano opzionale): richiede o meno l’autenticazione biometrica;
   *   se non presente, il default sarà considerato “false”.
   *   Tutte le logiche sulla disponibilità o il permesso dell’utente a tale funzionalità saranno rimandate al client.
   * - url: (stringa mandatoria) url della pagina da caricare.
   *   Non sarà un’ancora, ma direttamente la url della pagina web;
   *   i client si limiteranno a caricare, all’indirizzo indicato,
   *   la webview già a schermo, immediatamente o successivamente all’autenticazione biometrica,
   *   se necessaria. Se “​biometrics=false”​ o se “​biometrics=true”​,
   *   ma la funzionalità non è disponibile sul device, allora il client caricherà immediatamente la webview al nuovo indirizzo.
   *
   */
  loadPage(params: { url: string, biometrics: boolean }): Promise<any> {
    return this.manager.call('loadPage', params);
  }

  /**
   * Impostare alcuni elementi di UI nella pagina passati tramite JSON codificato in UTF 8:
   * {
   *  "title": <string>,
   *  "hideTOBi": <boolean>
   * }
   * dove:
   *  - "title": (​stringa opzionale) titolo della pagina ibrida da impostare sull’header nativo
   *  - "hideTOBi": (booleano opzionale) indica se nascondere TOBi everywhere.
   *    Il default sul client sarà false, per cui, sul client, sarà mostrato TOBi finché non arriverà una comunicazione diversa dal FE.
   *    ​(parametro ignorato dalla v11.0.0)
   */
  setupViewConfiguration(parameters: object): Promise<any> {
    return this.manager.call('setupViewConfiguration', {parameters});
  }

  /**
   * Permette di ricaricare la pagina sottostante,
   * se è una schermata ibrida allora verrà invocato un metodo esposto dal fe "reloadFromOverlay",
   * se è una pagina nativa verrà ricaricata (il comportamento dovrà essere definito in base alla schermata).
   *
   * @deprecated
   */
  reloadParent(): Promise<any> {
    return this.manager.call('reloadParent', {});
  }

  /**
   * Chiude tutti gli overlay aperti
   */
  closeOverlay(params: object): Promise<any> {
    return this.manager.call('closeOverlay', params);
  }

  /**
   * Permette al FE di aprire un overlay.
   * the parameter x-a in additionalParams will work only from 13.8 client releases
   */
  showOverlay(params: { pageURL: string, type: string, overlayType: string, forceFull?: boolean, additionalParams?: showOverlayAdditionalParams }): Promise<any> {
    return this.manager.call('showOverlay', params);
  }

  /**
   * Richiama un operation della schermata ibrida sottostante
   */
  jsFromOverlay(parameters: object): Promise<any> {
    return this.manager.call('jsFromOverlay', {...parameters});
  }

  /**
   * Permette di inviare dei dati al fe tramite una stringa json
   *
   * @deprecated
   */
  sendData(): Promise<any> {
    return this.manager.call('sendData', {});
  }

  /**
   * Chiede al Client di mostrare una schermata con i contatti dell’utente.
   */
  showAddressBook(): Promise<any> {
    return this.manager.call('showAddressBook', {});
  }

  /**
   * Chiede al Client i dati sul quale fare logica consensi
   */
  getAgreements(): Promise<any> {
    return this.manager.call('getAgreements', {});
  }

  /**
   * Open native sticky
   */
  showDrawer(params: ShowDrawerModel): Promise<any> {
    return this.manager.call('showDrawer', {params});
  }

  /**
   * Copy the text
   */
  copyTextToClipboard(text: string): Promise<any> {
    return this.manager.call('copyTextToClipboard', {text});
  }

  /**
   * Download Pdf Document
   */
  downloadPdf(params: { document: string, fileName: string }) {
    return this.manager.call('downloadPdf', { params });
  }

}
