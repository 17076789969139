import {Component, ElementRef, EventEmitter, Output, ViewEncapsulation} from '@angular/core';

const ATTRIBUTES = [
  'myvf-ui-button',
  'btnPrimary',
  'btnOutline',
  'btnOutlineDark',
  'btnOutlineDark2',
  'btnOutlineRed',
  'btnOutlineCalendar',
  'btnBlock',
  'btnDisabled',
  'btnStraight',
  'btnWhite',
  'btnLabelBold',
  'btnUpStraight',
  'btnHide',
  'btnSecondary',
  'btnSecondaryOutline',
  'btnNoPadding',
  'btnPaddingSm'
];

@Component({
  selector: 'button[myvf-ui-button]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {

  @Output() clickedButton: EventEmitter<any>;

  private _elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this.clickedButton = new EventEmitter<any>();

    for (const attr of ATTRIBUTES) {
      if (this._hasAttribute(attr)) {
        (<HTMLElement>elementRef.nativeElement).classList.add(attr);
      }
    }

  }

  private _getElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  private _hasAttribute(...attributes: string[]): boolean {
    return attributes.some(attribute => this._getElement().hasAttribute(attribute));
  }

  btnClicked(event) {
    this.clickedButton.emit(event);
  }
}
