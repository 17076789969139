<div class="myvf-alert-overlay">
  <div class="myvf-alert-dialog">
    <div class="myvf-alert-dialog-inner">
      <div class="myvf-alert-dialog-header">{{ title }}</div>
      <div class="myvf-alert-dialog-body">
        <ng-content></ng-content>
      </div>
      <div class="myvf-alert-dialog-footer" *ngIf="serviceNotify">
        <button myvf-ui-button btnOutlineDark btnStraight (click)="handleClose($event)">Chiudi</button>
        <button myvf-ui-button btnPrimary btnStraight (click)="handleRetry($event)">Riprova</button>
      </div>
    </div>
  </div>
</div>
