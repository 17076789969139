import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { AemGetCategoriesCatalog, AemGetCategoriesNews, AemGetDigitalBenefitsData, AemGetDigitalContainerData, AemGetMapping, AemGetMessages } from './shared/aem.action';
import { map } from 'rxjs/operators';
import { SessionState } from '@myvf/core';

export * from './aem.module';
export * from './shared/aem.action';
export * from './shared/aem.guard';
export * from './shared/aem.model';
export * from './shared/aem.state';

@Injectable()
export class AemMessagesResolver implements Resolve<any> {
  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const {user: {isNext}} = this.store.selectSnapshot(SessionState);

    const path = (!isNext) ?
      'content/myvf/configurations/messages.contents.json' : 'content/myvf/configurations/next-messages.contents.json';

    return this.store.dispatch(new AemGetMessages(path)).pipe(map(() => true));
  }
}

@Injectable()
export class AemMappingResolver implements Resolve<any> {
  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const path = 'content/myvf/configurations/mapping.contents.json';

    return this.store.dispatch(new AemGetMapping(path)).pipe(map(() => true));
  }
}

@Injectable()
export class AemCategoriesNewsResolver implements Resolve<any> {
  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const path = 'content/myvf/news/categories.news.categories.json';

    return this.store.dispatch(new AemGetCategoriesNews(path)).pipe(map(() => true));
  }
}

@Injectable()
export class AemCategoriesCatalogResolver implements Resolve<any> {
  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const path = 'content/myvf/next-catalog/categories.next-catalog.json';

    return this.store.dispatch(new AemGetCategoriesCatalog(path)).pipe(map(() => true));
  }
}

@Injectable()
export class AemDigitalContainerResolver implements Resolve<any> {
  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const path = 'content/myvf/configurations/digital-container.components.json';

    return this.store.dispatch(new AemGetDigitalContainerData(path)).pipe(map(() => true));
  }
}

@Injectable()
export class AemDigitalBenefitsResolver implements Resolve<any> {
  constructor(
    public store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const path = 'content/myvf/configurations/digital-benefits.components.json';

    return this.store.dispatch(new AemGetDigitalBenefitsData(path)).pipe(map(() => true));
  }
}

export function isInEditor(): boolean {
  const inIframe = window.self !== window.top;
  const haveAemCookie = document.cookie.indexOf('wcmmode') >= 0;
  return inIframe && haveAemCookie;
}
