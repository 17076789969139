import {Component, EventEmitter, HostListener, Input, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {is} from '@myvf/core';
import {QuickActionsProvider} from '../../../quick-actions';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import {CalendarComponent} from '../../calendar/calendar/calendar.component';

@Component({
  selector: 'myvf-ui-iservice-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {
  private _dateSelFrom: Date;
  private _dateSelTo: Date;
  private _dateFrom: Date;
  private _dateTo: Date;
  private _dateFromBCK: Date;
  private _dateToBCK: Date;

  resouce = environment.resource;

  // Tray
  screenHeight: number;
  maxHeight = 85;

  month = 0;

  @Input() showCalendar = false;
  @Input() range = false;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() maxRange: number | null = null;
  @Input() label = 'Modifica Periodo';
  @Input() ctaLabel = 'Applica';
  @Input() calendarImg: string;
  @Input() closeImg: string;
  @Input() todayOnCalendarOpen = false;

  @ViewChild('iServiceCalendar') iServiceCalendar:CalendarComponent;

  @Input()
  set dateFrom(date: Date) {
    if (is(Date, date)) {
      date.setHours(0, 0, 0, 0);
    }

    this._dateSelFrom = date !== null && this.minDate !== null && date < this.minDate ? this.minDate : date;
    this._dateFrom = this._dateSelFrom;
  }

  @Output() confirmClick: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private quickActions: QuickActionsProvider,
    vcRef: ViewContainerRef
  ) {
    quickActions.rootVcRef = vcRef;
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
  }

  get dateFrom(): Date {
    return this._dateFrom;
  }

  @Input()
  set dateTo(date: Date) {
    if (is(Date, date)) {
      date.setHours(0, 0, 0, 0);
    }

    this._dateSelTo = date !== null && this.maxDate !== null && date > this.maxDate ? this.maxDate : date;
    this._dateTo = this._dateSelTo;
  }

  get dateTo(): Date {
    return this._dateTo;
  }

  select(e) {
    this._dateSelFrom = e.dayFrom;
    this._dateSelTo = e.dayTo;

    this.dateFrom = this._dateSelFrom;
    this.dateTo = this._dateSelTo;
  }

  selectLastMonth(n: number) {
    this._dateSelFrom = dayjs().startOf('day').subtract(n, 'months').toDate();
    this._dateSelTo = dayjs().startOf('day').toDate();

    this.dateFrom = this._dateSelFrom;
    this.dateTo = this._dateSelTo;
    this.month = n;
    setTimeout(() => {
      this.iServiceCalendar.quickFilterByMonth();
    }, 250);
  }

  confirm(context) {
    this.dateFrom = this._dateSelFrom;
    this.dateTo = this._dateSelTo;
    this.showCalendar = false;
    this.confirmClick.emit({dateFrom: this.dateFrom, dateTo: this.dateTo});
    context.dismiss();
  }

  async onDatePickerClick(content) {
    this.showCalendar = !this.showCalendar;

    if (this.todayOnCalendarOpen && this.showCalendar) {
      this._dateFromBCK = this.dateFrom;
      this._dateToBCK = this.dateTo;

      this.dateFrom = new Date();
      this.dateTo = new Date();
    } else if (this.todayOnCalendarOpen && !this.showCalendar) {
      this.dateFrom = this._dateFromBCK;
      this.dateTo = this._dateToBCK;
    }

    await this.quickActions.show(content, {
      stops: [],
      maxHeight: Math.round(this.screenHeight * this.maxHeight / 100)
    });
  }

  onDismissTray(context) {
    context.dismiss();
  }

  checkIfActiveMonth(n: number) {
    if (!is(Date, this._dateFrom) || !is(Date, this._dateTo)) {
      return false;
    }

    const fromNMonth = dayjs().startOf('day').subtract(n, 'months').toDate();
    const toNMonth = dayjs().startOf('day').toDate();

    return dayjs(this._dateFrom).isSame(fromNMonth) && dayjs(this._dateTo).isSame(toNMonth);
  }
}
