<div class="datepicker-wrap">
  <a class="datepicker" (click)="onDatePickerClick(sheetTemplate)">
    <div class="date">
      <!--<div class="range" id="timePeriod">
        <span class="day" id="startDate">{{dateFrom | date:'dd'}} {{dateFrom | date:'MMM' | titlecase}} </span>
        <span *ngIf="dateTo && dateFrom.toLocaleDateString()!==dateTo.toLocaleDateString()" class="day" id="endDate">
          - {{dateTo | date:'dd'}} {{dateTo | date:'MMM' | titlecase}}
        </span>
      </div>-->
      <div class="calendar" id="modifyTimePeriod"> {{label}}</div>
      <div class="pencil"><img *ngIf="calendarImg" src="{{ calendarImg }}"></div>
    </div>
  </a>
  <ng-template #sheetTemplate let-context>
    <div>
      <div class="close" *ngIf="closeImg">
        <a (click)="onDismissTray(this.context)">
          <img src="{{ closeImg }}" srcset="{{ closeImg }}?scaling=1 1x, {{ closeImg }}?scaling=2 2x, {{ closeImg }}?scaling=3 3x" width="13" />
        </a>
      </div>
      <div class="fixed">
        <div class="container">
          <myvf-ui-iservice-calendar #iServiceCalendar [dateFrom]="dateFrom" [maxRange]="maxRange" [dateTo]="dateTo" [range]="range" [minDate]="minDate" [maxDate]="maxDate"
                                 (dateSelected)="select($event)"></myvf-ui-iservice-calendar>
        </div>
        <div class="separator">
          <button myvf-ui-button btnOutlineCalendar btnPaddingSm (click)="selectLastMonth(1)" [ngClass]="{'active': checkIfActiveMonth(1)}">Ultimo mese</button>
          <button myvf-ui-button btnOutlineCalendar btnPaddingSm (click)="selectLastMonth(2)" [ngClass]="{'active': checkIfActiveMonth(2)}">Ultimi 2 mesi</button>
        </div>
      </div>
      <div class="fixed-cta boxshadow-none">
        <button myvf-ui-button btnPrimary btnBlock (click)="confirm(this.context)">{{ctaLabel}}</button>
      </div>
    </div>
  </ng-template>
</div>
