import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {ApiModule} from './api.module';

export interface ClientRegisterModel {
  app_id: string;
  app_platform: string;
  app_version: string;
  device_udid: string;
}

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class ClientApiService {
  constructor(
    private http: BaseApiService
  ) {
  }

  register(params: ClientRegisterModel): Observable<any> {
    return this.http.post('/api/v1/client/register', params);
  }
}
