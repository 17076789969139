import {LineupStickyMailComponent} from '../../legacy-lineup/components/lineup-sticky-mail/lineup-sticky-mail.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyvfUiModule} from '@myvf/ui';
import {StickyComponent} from './sticky/sticky.component';
import {FormsModule} from '@angular/forms';
import {StickyMailComponent} from '../../legacy-catalog/components/sticky-mail/sticky-mail.component';
import {LegacyStickyCheckActivationComponent} from '../../legacy-catalog/components/legacy-sticky-check-activation/legacy-sticky-check-activation.component';

@NgModule({
  imports: [
    CommonModule,
    MyvfUiModule,
    FormsModule
  ],
  declarations: [
    StickyComponent,
    StickyMailComponent,
    LegacyStickyCheckActivationComponent,
    LineupStickyMailComponent
  ],
  exports: [
    StickyComponent
  ]
})
export class StickyModule {
}
