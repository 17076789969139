import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {CalendarModule} from '../calendar/calendar.module';

@NgModule({
  imports: [
    CommonModule,
    CalendarModule
  ],
  declarations: [
    DatepickerComponent
  ],
  exports: [
    DatepickerComponent,
  ]
})
export class DatepickerModule {
}
