import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AccordionModule} from './accordion';
import {AlertModule} from './alert';
import {ButtonModule} from './button';
import {CalendarModule} from './calendar';
import {CalendarModule as iServiceCalendarModule} from './iService/calendar';
import {CardModule} from './card';
import {CarouselModule} from './carousel';
import {DatepickerModule} from './datepicker';
import {DatepickerModule as iServiceDatepickerModule} from './iService/datepicker';
import {EaseModule} from './ease';
import {FilterModule} from './filter';
import {IconModule} from './icon';
import {ListModule} from './list';
import {SlideToggleModule} from './slide-toggle';
import {SpinnerModule} from './spinner';
import {StickyFeedModule} from './sticky-feed';
import {TabsModule} from './tabs';
import {MarqueeModule} from './marquee';
import { ConfirmationErrorPageModule } from './confirmation-error-page/confirmation-page.module';
import { LoaderVfModule } from './loader-vf';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    AlertModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CardModule,
    DatepickerModule,
    EaseModule,
    FilterModule,
    IconModule,
    ListModule,
    SlideToggleModule,
    SpinnerModule,
    StickyFeedModule,
    TabsModule,
    MarqueeModule,
    iServiceDatepickerModule,
    iServiceCalendarModule,
    ConfirmationErrorPageModule,
    LoaderVfModule
  ],
  exports: [
    AccordionModule,
    AlertModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CardModule,
    DatepickerModule,
    EaseModule,
    FilterModule,
    IconModule,
    ListModule,
    SlideToggleModule,
    SpinnerModule,
    StickyFeedModule,
    TabsModule,
    MarqueeModule,
    iServiceDatepickerModule,
    iServiceCalendarModule,
    ConfirmationErrorPageModule,
    LoaderVfModule
  ]
})
export class MyvfUiModule {
}
