import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {CORE_CONFIGURATION} from '../configuration';
import {LoggerService} from './logger.service';
import {
  AWS_FIRST_VERSION,
  CLIENT_ID,
  HEADER_API, HEADER_APP_CONSUMER_AUTH,
  HEADER_AUTH_TOKEN,
  HEADER_CLIENT_ID,
  HEADER_DEVICE_ID,
  HEADER_DEVICE_USER_AGENT,
  HEADER_ENVIRONMENT,
  HEADER_USER_SESSION_ID,
  STORAGE_SESSION
} from '../constants';
import {StorageService} from '../storage/storage.service';
import {SessionState} from '../session/session.state';
import {SessionStateModel} from '../session/session.model';
import semver from 'semver';
import {isInEditor} from '@myvf/aem';
import {decodeCookie} from '@myvf/core';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(CORE_CONFIGURATION) private config,
    private logger: LoggerService,
    private storage: StorageService,
    private store: Store
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const session: SessionStateModel = this.store.selectSnapshot(SessionState);
    let headers: HttpHeaders = req.headers;

    const isHeaderApiPresent = headers.has(HEADER_API);
    const awsAppVersionSatisfied = semver.satisfies(session.appVersion, `>= ${AWS_FIRST_VERSION}`);

    if (isHeaderApiPresent) {
      if (awsAppVersionSatisfied) {
        headers = this.setOnCloudHeaders(session, headers);
      } else {
        headers = this.setOnPremiseHeaders(session, headers);
      }
    }

    let request = req.clone({headers});

    if (
      awsAppVersionSatisfied &&
      req.url.includes('/api/') &&
      !isInEditor()
    ) {
      request = this.cloneRequestWithUpdatedUrl(request, '/api/', `/${this.config.environment}/api/`);
    }

    if (
      session.initiative &&
      awsAppVersionSatisfied &&
      !isInEditor()
    ) {
      request = this.cloneRequestWithUpdatedUrl(request, `/${this.config.environment}/`, `/${this.config.environment}/${session.initiative}/`);
    }

    this.logger.group('HTTP Client: Request', request);

    return next
      .handle(request)
      .pipe(
        filter(response => response instanceof HttpResponse),
        tap(
          (response: HttpResponse<any>) => {
            if (response.headers.has(HEADER_USER_SESSION_ID)) {
              response.body.sessionId = response.headers.get(HEADER_USER_SESSION_ID);
            }

            this.logger.group('HTTP Client: Response', response);
          },
          error => this.logger.error(error)
        )
      );
  }

  /**
   * clone request with updated url
   * @param req http request
   * @param searchValue search value
   * @param replaceValue replace value
   */
  private cloneRequestWithUpdatedUrl(req: HttpRequest<any>, searchValue: string, replaceValue: string): HttpRequest<any> {
    return req.clone({ url: req.url.replace(searchValue, replaceValue) });
  }

  /**
   * set headers for on Premise requests
   * @param session session data
   * @param headers http header request
   */
  public setOnPremiseHeaders(session: SessionStateModel, headers: HttpHeaders): HttpHeaders {
    headers = headers
      .set('Content-Type', 'application/json')
      .set(HEADER_ENVIRONMENT, this.config.environment)
      .set(HEADER_CLIENT_ID, CLIENT_ID)
      .delete(HEADER_API);

    if (session.userAgent) {
      headers = headers
        .set(HEADER_DEVICE_USER_AGENT, session.userAgent);
    }

    if (session.auth.token) {
      headers = headers.set(HEADER_AUTH_TOKEN, session.auth.token);
    }

    if (session.auth.installation_id) {
      headers = headers
        .set(HEADER_DEVICE_ID, session.auth.installation_id);
    }

    if (session.auth.session_id) {
      headers = headers
        .set(HEADER_USER_SESSION_ID, session.auth.session_id);
    }

    return headers;
  }

  /**
   * set headers for on Cloud requests
   * @param session session data
   * @param headers http header request
   */
  public setOnCloudHeaders(session: SessionStateModel, headers: HttpHeaders): HttpHeaders {
    // getting from storage in case coming from aws login
    const {installation_id}: any = decodeCookie(this.storage.local.pull(STORAGE_SESSION) as string);

    headers = headers
      .set('Content-Type', 'application/json')
      .delete(HEADER_API);

    if (session.userAgent) {
      headers = headers
        .set(HEADER_DEVICE_USER_AGENT.toLowerCase(), session.userAgent);
    }

    if (session.auth.token) {
      headers = headers.set(HEADER_APP_CONSUMER_AUTH, session.auth.token);
    }

    if (session.auth.installation_id || installation_id) {
      headers = headers
        .set(HEADER_DEVICE_ID.toLowerCase(), session.auth.installation_id || installation_id);
    }

    return headers;
  }
}
