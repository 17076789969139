import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AemInitialize} from './aem.action';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AemGuard implements CanActivate, CanActivateChild {
  private preUrl = null;
  private url = '';

  constructor(
    private store: Store
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let urlWithoutQuery = state.url.replace(/\?.*$/, '');

    if (/\(.+?:.+?\)/.test(urlWithoutQuery)) {
      urlWithoutQuery = urlWithoutQuery.split(':')[1]?.replace(')','');
    }
    const resolve = (r: string) => `/${r.replace(/\.[^/.].*$/, '').replace(/^\//, '')}.webview.json`;
    this.url = resolve(urlWithoutQuery);

    if (!this.preUrl || this.preUrl !== this.url) {
      this.preUrl = this.url;

      return this.store
        .dispatch(new AemInitialize(this.url))
        .pipe(
          map(() => true)
        );
    }

    return of(true);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
