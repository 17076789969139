import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MarqueeComponent} from './marquee/marquee.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MarqueeComponent
  ],
  exports: [
    MarqueeComponent
  ]
})
export class MarqueeModule {
}
