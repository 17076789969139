<div class="sheet" #sheet>
  <div
    class="sheet-content"
    [style.height]="height"
    [style.maxHeight]="maxHeight"
  >
    <ng-template
      [cdkPortalOutlet]="contentPortal"
      (attached)="attached($event)"
    ></ng-template>
  </div>
</div>
