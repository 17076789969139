import { Inject, Injectable } from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {ApiModule} from './api.module';
import { CORE_CONFIGURATION } from '@myvf/core';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class ExternalservicesApiService {
  constructor(
    private http: BaseApiService,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  /**
   * items api get items
   * @param fiscalCode user fiscal code
   * @param hideError hide error
   */
  retrieveFlexPageUrl(
    fiscalCode: string,
    hideError?: boolean,
  ): Observable<any> {
    const path = hideError ? `/${this.config.environment}/externalservices/v1/compass/retrieveFlexPageUrl?hideError=true` : `/${this.config.environment}/externalservices/v1/compass/retrieveFlexPageUrl`;

    return this.http.post(path, {fiscalCode}, true);
  }
}
