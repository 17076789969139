import { BaseApiService } from './base-api.service';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SessionState, SessionStateModel } from '../session';
import { Store } from '@ngxs/store';
import { CORE_CONFIGURATION } from '../configuration';

@Injectable({
  providedIn: 'root',
  deps: [BaseApiService]
})
export class AnchorsApiService {
  /**
  * Constructor
  * @param http api service
  */
  constructor(
    private http: BaseApiService,
    private store: Store,
    @Inject(CORE_CONFIGURATION) private config
  ) { }


  getLookup(anchorId: string, args?: any): Observable<any> {
    let params = new HttpParams();
    const {
      customerId,
      contactId,
      user,
      status
    }: SessionStateModel = this.store.selectSnapshot(SessionState);

    const isNext = user?.isNext;
    const fiscalCode = user?.fiscalCode;
    const username = user?.username;

    let url = `/api/v3/anchors/lookup/${anchorId}`; //Legacy

    //if next or not authenticated (for anonymous anchors)
    if (isNext || !user) {
      url = `/${this.config.environment}/anchorresolver/v1/lookup`;

      params = params.set('anchorId', anchorId); // Include anchorId as a query parameter

      const mandatoryArgs = {
        ...username && {username: username},
        customerId: customerId ? customerId : '',
        contactId: contactId ? contactId : '',
        fiscalCode: fiscalCode ? fiscalCode : '',
        status: status ? status : ''
      };

      Object.keys(mandatoryArgs).forEach((v) => {
        params = params.set(v, mandatoryArgs[v]);
      });
    }

    if (args) {
      Object.keys(args).forEach((v) => {
        params = params.set(v, args[v]);
      });
    }

    return this.http.get(url, params).pipe(
      map(data => data?.result ? data : { result: data }) //external anchor onPrem/aws handling different format
    );
  }

}

