import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '../icon';
import {StickyFeedComponent} from './sticky-feed/sticky-feed.component';
import {StickyBodyDirective} from './sticky-feed/sticky-body.directive';
import {StickyTitleDirective} from './sticky-feed/sticky-title.directive';
import {StickyFeedActionComponent} from './sticky-feed-action/sticky-feed-action.component';
import {StickyFooterDirective} from './sticky-feed/sticky-footer.directive';
import {StickyFeedIconComponent} from './sticky-feed-icon/sticky-feed-icon.component';
import {StickyHostDirective} from './sticky-feed/sticky-host.directive';

@NgModule({
  imports: [
    CommonModule,
    IconModule
  ],
  declarations: [
    StickyTitleDirective,
    StickyBodyDirective,
    StickyHostDirective,
    StickyFooterDirective,
    StickyFeedComponent,
    StickyFeedActionComponent,
    StickyFeedIconComponent
  ],
  exports: [
    StickyTitleDirective,
    StickyBodyDirective,
    StickyFooterDirective,
    StickyHostDirective,
    StickyFeedComponent,
    StickyFeedActionComponent,
    StickyFeedIconComponent
  ]
})
export class StickyFeedModule {
}
