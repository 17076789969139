<myvf-ui-sticky-feed *ngIf="visible" [type]="template" @fadeInOut [ngClass]="data.context">
  <h1 stickyTitle *ngIf="data?.title">{{data.title}}</h1>
  <p stickyBody *ngIf="data?.body" [innerHTML]="data.body"></p>
  <p stickyFooter *ngIf="data?.footer">{{data.footer}}</p>
  <ng-template stickyHost></ng-template>
  <myvf-ui-sticky-feed-action *ngIf="data?.actions">
    <button
      myvf-ui-button
      btnBlock
      btnWhite
      btnStraight
      btnLabelBold
      (clickedButton)="data.actions.ok.action()"
      *ngIf="data?.actions.ok">
      {{data?.actions.ok.label}}
    </button>
    <button
      myvf-ui-button
      btnBlock
      btnOutline
      btnStraight
      btnLabelBold
      (clickedButton)="data.actions.cancel.action()"
      *ngIf="data?.actions.cancel">
      {{data.actions.cancel.label}}
    </button>
  </myvf-ui-sticky-feed-action>
</myvf-ui-sticky-feed>
