import {Action, State, StateContext, Store} from '@ngxs/store';
import {filter, mergeMap, switchMap, tap} from 'rxjs/operators';
import {Login, Logout, Register, Start, SwitchItem, SwitchNumber, SwitchSim} from './auth.action';
import {AuthStateModel} from './auth.model';
import {ApiService} from '../api/api.service';
import {StorageService} from '../storage/storage.service';
import {STORAGE_DEV, STORAGE_SESSION} from '../constants';
import {decodeCookie, encodeCookie} from '../util.class';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    installation_id: '',
    session_id: '',
    token: ''
  }
})
@Injectable()
export class AuthState {
  constructor(
    private api: ApiService,
    private storage: StorageService,
    private store: Store
  ) {
  }

  @Action(Register)
  register({patchState, dispatch}: StateContext<AuthStateModel>, {params}: Register) {
    this.storage.local.delete(STORAGE_SESSION);

    return this.api
      .device
      .create(params)
      .pipe(
        switchMap(data => {
          const {installationId, appPlatform, appVersion, osVersion} = data.result;
          const {session: {userAgent: customerUserAgent}} = this.store.snapshot();

          this.storage.local.push(
            STORAGE_SESSION,
            encodeCookie({
              installation_id: installationId
            })
          );

          this.storage.local.push(
            STORAGE_DEV,
            encodeCookie({
              appPlatform,
              appVersion,
              installationId,
              osVersion,
              customerUserAgent
            })
          );

          patchState({
            installation_id: installationId
          });

          return dispatch(new Start());
        })
      );
  }

  @Action(Start)
  start({patchState, getState}: StateContext<AuthStateModel>) {
    const {installation_id} = getState();

    return this.api
      .auth
      .guest()
      .pipe(
        tap(data => {
          const {sessionId, result: {token}} = data;

          this.storage.local.push(
            STORAGE_SESSION,
            encodeCookie({
              session_id: sessionId,
              installation_id: installation_id,
              secret_key: '',
              mid: '',
              token
            })
          );

          this.storage.local.push(
            STORAGE_DEV,
            encodeCookie({
              ...decodeCookie(this.storage.local.pull(STORAGE_DEV) as string),
              sessionId,
              token
            })
          );

          patchState({
            session_id: sessionId,
            token
          });
        })
      );
  }

  @Action(Login)
  login({dispatch, patchState}: StateContext<AuthStateModel>, {params, register}: Login) {
    const {username, password} = params;

    return dispatch(new Register(register))
      .pipe(
        switchMap(() =>
          this.api
            .auth
            .login(username, password)
            .pipe(
              filter(data => data.result && data.result['customer'] && data.result['items']),
              tap(data => {
                const {customer: {email, firstname, fiscalCode, username: user}, items, isNext, customerId} = data.result;
                const sims = items.map(i => ({value: i.value, type: i.type}));

                const storage = {
                  ...decodeCookie(this.storage.local.pull(STORAGE_DEV) as string),
                  ...{
                    email,
                    firstname,
                    fiscalCode,
                    username: user,
                    isNext: isNext ? isNext : false,
                    customerId,
                    items
                  }
                };

                this.storage.local.push(
                  STORAGE_DEV,
                  encodeCookie(storage)
                );

                patchState({sims} as any);
              })
            )
        )
      );
  }

  @Action(SwitchItem)
  switchItem({dispatch}: StateContext<AuthStateModel>, {item}: SwitchItem) {
    switch (item.type) {
    case 'sim':
      return dispatch(new SwitchSim(item));
    case 'landline':
      return dispatch(new SwitchNumber(item));
    }
  }

  @Action(SwitchSim)
  switchSim({patchState}: StateContext<AuthStateModel>, {sim}: SwitchSim) {
    const {value, type} = sim;
    const {session: {auth: {sims}}} = this.store.snapshot();

    if ( type === 'sim' && sims ) {
      const agreements = {
        agreements: {
          landlines: sims.filter((el) => el.type === 'landline').map((el) => {
            return {'agreementsVersion': 'agreements_not_seen', 'value': el.value};
          }),
          sims: sims.filter((el) => el.type === 'sim').map((el) => {
            return {'agreementsVersion': 'agreements_not_seen', 'value': el.value};
          })
        }
      };
      return this.api
        .sim
        .detailsPost(value, agreements)
        .pipe(
          tap(this.patchStateSwitchItem(value, type))
        );
    } else {
      return this.api
        .sim
        .details(value)
        .pipe(
          tap(this.patchStateSwitchItem(value, type))
        );
    }
  }

  @Action(SwitchNumber)
  switchNumber({patchState}: StateContext<AuthStateModel>, {number}: SwitchNumber) {
    const {value, type} = number;

    return this.api
      .landline
      .details(value)
      .pipe(
        mergeMap(data => of({
          ...data,
          result: {
            ...data.result,
            info: {
              ...data.result.info,
              paymentType: null,
              profile: {
                code: data.result.info.data_mirror_code
              }
            }
          }
        })),
        tap(this.patchStateSwitchItem(value, type))
      );
  }

  patchStateSwitchItem(value, type) {
    return (data) => {
      const {info: {profile: {code}, paymentType, role}, agreements} = data.result;

      if (agreements && (agreements.landlines || agreements.sims)) {
        this.storage.local.push('agreements', agreements);
      }
      const storage = {
        ...decodeCookie(this.storage.local.pull(STORAGE_DEV) as string),
        ...{
          profile: code,
          status: 'authenticated',
          type,
          type_sim: paymentType,
          value,
          role: role ? role : ''
        }
      };

      this.storage.local.push(
        STORAGE_DEV,
        encodeCookie(storage)
      );
    };
  }

  @Action(Logout)
  logout() {
  }
}
