import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MiscApiService {

  constructor(
    private http: BaseApiService
  ) {
  }

  scrape(url: string, type: string = 'product'): Observable<any> {
    let params = new HttpParams();
    params = params.set('type', type);
    params = params.set('urlBase64', encodeURIComponent(url));
    params = params.set('noLoader', 'true');

    return this.http.get('/api/v3/misc/scrape', params);
  }
}
