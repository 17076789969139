import {Component} from '@angular/core';
import {OneTimePasswordService} from './one-time-password.service';
import {OneTimePasswordEvent} from './one-time-password-event.type';
import {ApiService, SessionState} from '@myvf/core';
import {Store} from '@ngxs/store';
import {filter, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TealiumAnalyticsService} from '../../tagging/tealium/tealium-analytics.service';
import {AemState} from '@myvf/aem';

/**
 * component for manage one time password by junior users for operative actions
 */
@Component({
  selector: 'myvf-otp',
  templateUrl: './one-time-password.component.html',
  styleUrls: ['./one-time-password.component.scss']
})
export class OneTimePasswordComponent {
  public show = false;
  public data: OneTimePasswordEvent;
  public msisdn: string;
  public inputOtp: string;
  public errorMsg: string;
  public triggerDisabled = false;

  // TODO spostare o mergiare questi dati di fb con quelli del servizio di mapping aem quando merge
  public errorMapping = {
    '392': 'PIN non corretto. Riprova.',
    '310': 'PIN non corretto. Riprova.',
    '399': 'E\' stato inserito per 3 volte un PIN non corretto. Riprova tra 30 minuti.',
    '362': 'Il PIN inserito è scaduto. Richiedine uno nuovo e ricorda di utilizzarlo entro 30 minuti da quando lo ricevi.',
    '500': 'Servizio momentaneamente non disponibile. Riprova più tardi.',
    '490': 'Servizio momentaneamente non disponibile. Riprova più tardi.',
  };

  constructor(
    public otpService: OneTimePasswordService,
    public api: ApiService,
    public store: Store,
    public tealium: TealiumAnalyticsService
  ) {
    const {value: msisdn} = this.store.selectSnapshot(SessionState);
    this.msisdn = msisdn;

    this.otpService
      .events
      .pipe(
        filter(({show}) => show),
        switchMap(event =>
          this.createTutorOtp(msisdn)
            .pipe(
              map(() => event)
            )
        )
      )
      .subscribe(({show, event}) => {
        this.triggerDisabled = false;
        this.show = show;
        this.data = event;
        this.inputOtp = '';
        this.errorMsg = '';
        if (event.taggingInfo) {
          this.tealium.push('view', event.taggingInfo);
        }
      });
  }

  /**
   * action for call api createTutorOtp
   * @param msisdn current number
   */
  public createTutorOtp(msisdn): Observable<any> {
    return this.api
      .sim
      .details(msisdn)
      .pipe(
        switchMap(({result: {info: {simId}}}) => this.api
          .oneTimePassword
          .createTutorOtp(simId, msisdn)
        )
      );
  }

  /**
   * resend click event handler for new create otp on demand
   */
  public onResendClick() {
    this.createTutorOtp(this.msisdn)
      .subscribe();
  }

  /**
   * confirm click handler, check otp api with input otp
   */
  public onConfirmClick() {
    if (!this.inputOtp) {
      this.errorMsg = 'Codice Obbligatorio';
    } else {
      this.api
        .oneTimePassword
        .checkTutorOtp(this.msisdn, this.inputOtp)
        .subscribe(({retCode}) => {
          if (retCode === 200) {
            this.data.successFunction();
          } else {
            const {messages} = this.store.selectSnapshot(AemState);

            this.errorMsg = messages && messages.junior && messages.junior.find(d => d.code === retCode)
              ? messages.junior.find(d => d.code === retCode).message
              : this.errorMapping[retCode];
          }
        });
    }
  }

  /**
   * input keydown event handler for hide error message when user types otp
   */
  public hideErrors() {
    this.errorMsg = '';
  }
}
