import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {CORE_CONFIGURATION} from '../configuration';

@Injectable({
  providedIn: 'root',
  deps: [
    CORE_CONFIGURATION,
    ErrorHandler
  ]
})
export class LoggerService {
  constructor(
    @Inject(CORE_CONFIGURATION) private config,
    private errorHandler: ErrorHandler
  ) {
  }

  group(name: string, ...logger: any[]) {
    if (this.config.enableTracing) {
      console.group(name);
      logger.forEach(log => console.log(log));
      console.groupEnd();
    }
  }

  info(value: any, ...rest: any[]): void {
    if (this.config.enableTracing) {
      console.log(value, ...rest);
    }
  }

  warn(value: any, ...rest: any[]): void {
    if (this.config.enableTracing) {
      console.warn(value, ...rest);
    }
  }

  error(error: Error): void {
    if (this.config.enableTracing) {
      this.errorHandler.handleError(error);
    }
  }
}
