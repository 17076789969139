import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './alert/alert.component';
import {ButtonModule} from '../button';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule
  ],
  declarations: [AlertComponent],
  exports: [AlertComponent]
})
export class AlertModule {
}
