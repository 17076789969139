import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {MyvfUiModule} from '@myvf/ui';
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from './loader/loader.service';
import {LoaderInterceptorService} from './loader/loader-interceptor.service';
import {WindowService} from '@myvf/core';

@NgModule({
  imports: [
    CommonModule,
    MyvfUiModule
  ],
  declarations: [
    LoaderComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }
  ],
  exports: [
    LoaderComponent
  ]
})
export class LoaderModule {
  constructor(
    private router: Router,
    private service: LoaderService,
    public window: WindowService
  ) {
    router
      .events
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          if (!event.url || !this.window.decodeQueryParams(event.url).hasOwnProperty('noLoader')) {
            this.service.show();
          }
        }

        if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.service.hide();
        }
      });
  }
}
