import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderVfComponent} from './loader-vf/loader-vf.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LoaderVfComponent
  ],
  exports: [
    LoaderVfComponent,
  ]
})
export class LoaderVfModule {
}
