import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {delay, dematerialize, materialize, mergeMap} from 'rxjs/operators';
import {mockApi} from './mock-api.constants';
import {CORE_CONFIGURATION} from '../configuration';
import {mockAWSApi} from './mock-aws-api.constants';

@Injectable()
export class FakeApiInterceptorService implements HttpInterceptor {
  constructor(@Inject(CORE_CONFIGURATION) private config) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.config.isInEditor) {
      return of(null)
        .pipe(
          mergeMap(() => {
            const reqUrl = req.urlWithParams
              .replace(this.config.apiHost, '');

            if (reqUrl.startsWith('/api') || reqUrl.startsWith(`/${this.config.environment}/api`)) {
              const service = Object.keys(mockApi).find(key => (new RegExp(key)).test(reqUrl));

              if (service) {
                return of(new HttpResponse({status: 200, body: mockApi[service]}));
              } else {
                return throwError(new Error(`Cannot find on premises response mock for ${reqUrl}`));
              }
            } else if (reqUrl.startsWith(`/${this.config.environment}/`)) {
              const service = Object.keys(mockAWSApi).find(key => (new RegExp(key)).test(reqUrl.replace(`/${this.config.environment}/`, '/')));
              if (service) {
                return of(new HttpResponse({status: 200, body: mockAWSApi[service]}));
              } else {
                return throwError(new Error(`Cannot find aws response mock for ${reqUrl}`));
              }
            }

            return next.handle(req);
          }),
          materialize(),
          delay(1000),
          dematerialize()
        );
    }

    return next.handle(req);
  }
}
