import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListComponent} from './list/list.component';
import { ListItemComponent } from './list-item/list-item.component';
import { ListItemBodyComponent } from './list-item-body/list-item-body.component';
import { ListItemIconComponent } from './list-item-icon/list-item-icon.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ListComponent,
    ListItemComponent,
    ListItemBodyComponent,
    ListItemIconComponent
  ],
  exports: [
    ListComponent,
    ListItemComponent,
    ListItemBodyComponent,
    ListItemIconComponent
  ]
})
export class ListModule {
}
