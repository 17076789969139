import {Injectable} from '@angular/core';
import {ApiModule} from './api.module';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';

/**
 * api service tecnical
 */
@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class OneTimePasswordApiService {
  public readonly CHANNEL = 'MOBILE';

  constructor(public http: BaseApiService) {
  }

  /**
   * used for one time password creation
   * @param msisdn number junior
   */
  createTutorOtp(simId: string, msisdn: string): Observable<any> {
    this.http.header('channelType', 'BE');
    return this.http.post('/OneTimePassword/v1/createTutorOtp', {simIdChild: simId, msisdnChild: msisdn, channel: this.CHANNEL});
  }

  /**
   * user for comparison bewteen inserted otp and created otp
   * @param msisdn number junior
   * @param otp inserted otp
   */
  checkTutorOtp(msisdn: string, otp: string): Observable<any> {
    this.http.header('channelType', 'BE');
    return this.http.post('/OneTimePassword/v1/checkTutorOtp', {msisdnChild: msisdn, otp, channel: this.CHANNEL});
  }
}
