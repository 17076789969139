import {concat, EMPTY, of} from 'rxjs';
import {concatMap, delay} from 'rxjs/operators';
import {AfterContentChecked, Component, ComponentFactoryResolver, Type, ViewChild} from '@angular/core';
import {StickyService, StickyServiceEvent} from './sticky.service';
import {StickyEvent} from './sticky-event.type';
import {animate, query, style, transition, trigger} from '@angular/animations';
import {StickyFeedComponent} from '../../../ui/sticky-feed';
import {STICKY_DELAY} from './sticky.constants';

@Component({
  selector: 'myvf-sticky',
  templateUrl: './sticky.component.html',
  styleUrls: ['./sticky.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        query('.myvf-sticky', [
          style({
            transform: 'translateY(100%)'
          }),
          animate(
            `${STICKY_DELAY}ms ease-in`,
            style({
              transform: 'translateY(0)'
            })
          )
        ])
      ]),
      transition(':leave', [
        query('.myvf-sticky', [
          style({
            transform: 'translateY(0)'
          }),
          animate(
            `${STICKY_DELAY}ms ease-out`,
            style({
              transform: 'translateY(100%)'
            })
          )
        ])
      ])
    ])
  ]
})
export class StickyComponent implements AfterContentChecked {

  private oldVisible: boolean;

  visible = false;
  template: string;
  data: StickyEvent;

  @ViewChild(StickyFeedComponent) componentRef: StickyFeedComponent;

  constructor(
    private service: StickyService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.service
      .events
      .pipe(
        concatMap(i => concat(
          of(i),
          EMPTY.pipe(delay(STICKY_DELAY))
        ))
      )
      .subscribe(({event, show}: StickyServiceEvent) => {
        if (event) {
          this.template = event.type;
          this.data = event;
        }

        this.visible = show;
      });
  }

  ngAfterContentChecked(): void {
    if (this.visible !== this.oldVisible && this.componentRef) {
      if (this.visible && this.data.component) {
        this.appendComponent(this.data.component);
      }

      this.oldVisible = this.visible;
    }
  }

  appendComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.componentRef.stickyHost.viewContainerRef;

    viewContainerRef.clear();

    viewContainerRef.createComponent(componentFactory);
  }
}
