import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Action, State, StateContext} from '@ngxs/store';
import {AemModelClientService} from './aem-model-client.service';
import {AemGetCategoriesCatalog, AemGetCategoriesNews, AemGetDigitalBenefitsData, AemGetDigitalContainerData, AemGetMapping, AemGetMessages, AemInitialize} from './aem.action';
import {AemStateModel} from './aem.model';

@State<AemStateModel>({
  name: 'aem',
  defaults: {
    title: '',
    configuration: null,
    categoriesNews: null,
    categoriesCatalog: null,
    digitalContainerData: null,
    digitalBenefitsData: null,
    messages: null,
    mapping: null,
  },
})
@Injectable()
export class AemState {
  constructor(
    public aemClient: AemModelClientService,
    public route: ActivatedRoute
  ) {
  }

  @Action(AemInitialize)
  async initialize({setState}: StateContext<AemStateModel>, {path}: any) {
    const configuration = await this.aemClient.fetch(path);

    setState({configuration: configuration});
  }

  @Action(AemGetMessages)
  async getMessages({setState, getState}: StateContext<AemStateModel>, {path}: any) {

    const messages = await this.aemClient.fetch(path);

    setState({...getState(), ...messages});
  }

  @Action(AemGetMapping)
  async getMapping({setState, getState}: StateContext<AemStateModel>, {path}: any) {
    const mapping = await this.aemClient.fetch(path);

    setState({...getState(), ...mapping});
  }

  @Action(AemGetCategoriesNews)
  async getCategoriesNews({setState, getState}: StateContext<AemStateModel>, {path}: any) {
    const categoriesNews = await this.aemClient.fetch(path);

    setState({...getState(), ...{categoriesNews:  [...categoriesNews]}});
  }

  @Action(AemGetCategoriesCatalog)
  async getCategoriesCatalog({setState, getState}: StateContext<AemStateModel>, {path}: any) {
    const categoriesCatalog = await this.aemClient.fetch(path);

    setState({...getState(), ...{categoriesCatalog:  [...categoriesCatalog]}});
  }

  @Action(AemGetDigitalContainerData)
  async getDigitalContainerData({setState, getState}: StateContext<AemStateModel>, {path}: any) {
    const digitalContainerData = await this.aemClient.fetch(path);

    setState({...getState(), ...{digitalContainerData:  [...digitalContainerData]}});
  }

  @Action(AemGetDigitalBenefitsData)
  async getDigitalBenefitsData({setState, getState}: StateContext<AemStateModel>, {path}: any) {
    const digitalBenefitsData = await this.aemClient.fetch(path);

    setState({...getState(), digitalBenefitsData});
  }
}

