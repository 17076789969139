import {Action, State, StateContext, Store} from '@ngxs/store';
import {AwsAuthorizationStateModel} from './aws-authorization.model';
import {ApiService} from '../api/api.service';
import {StorageService} from '../storage/storage.service';
import {Injectable} from '@angular/core';
import {STORAGE_DEV, STORAGE_SESSION, HEADER_APP_CONSUMER_AUTH} from '../constants';
import {filter, switchMap, tap} from 'rxjs/operators';
import {decodeCookie, encodeCookie} from '../util.class';
import {AwsDevice, AwsLogin, AwsSwitchItem} from './aws-authorization.action';

@State<AwsAuthorizationStateModel>({
  name: 'awsAuthentication',
  defaults: {
    installation_id: '',
    items: [],
    token: '',
    focusedItem: null
  }
})
@Injectable()
export class AwsAuthorizationState {
  constructor(
    private api: ApiService,
    private storage: StorageService,
    private store: Store
  ) {
  }

  @Action(AwsDevice)
  awsDevice({patchState, dispatch}: StateContext<AwsAuthorizationStateModel>, {params}: AwsDevice) {
    this.storage.local.delete(STORAGE_SESSION);

    return this.api
      .device
      .awsCreate(params)
      .pipe(
        tap(({installationId, appPlatform, appVersion, osVersion}) => {
          const {session: {userAgent: customerUserAgent}} = this.store.snapshot();

          this.storage.local.push(
            STORAGE_SESSION,
            encodeCookie({
              installation_id: installationId
            })
          );

          this.storage.local.push(
            STORAGE_DEV,
            encodeCookie({
              appPlatform,
              appVersion,
              installationId,
              osVersion,
              customerUserAgent
            })
          );

          patchState({
            installation_id: installationId
          });
        }));
  }

  @Action(AwsLogin)
  awsLogin({dispatch, patchState}: StateContext<AwsAuthorizationStateModel>, {params, register}: AwsLogin) {
    const {username, password} = params;

    return dispatch(new AwsDevice(register))
      .pipe(
        switchMap(() => {
          return this.api
            .auth
            .loginByUsernameAndPassword(username, password)
            .pipe(
              filter(data => data && data.body && data.body['customer'] && data.body['items'] && data.headers.get(HEADER_APP_CONSUMER_AUTH)),
              tap(({body: data, headers}) => {
                const {isNext, customerId, contactId, customer: {email, firstname, lastname, fiscalCode, username: user, isJunior}, items} = data;
                const token = headers.get(HEADER_APP_CONSUMER_AUTH);

                this.storage.local.push(
                  STORAGE_SESSION,
                  encodeCookie({
                    ...decodeCookie(this.storage.local.pull(STORAGE_SESSION) as string),
                    items: JSON.stringify(items.map(({type, value, planType}) => ({type, value, planType}))),
                    token
                  })
                );

                const storage = {
                  ...decodeCookie(this.storage.local.pull(STORAGE_DEV) as string),
                  ...{
                    customerId,
                    contactId,
                    email,
                    firstname,
                    lastname,
                    fiscalCode,
                    username: user,
                    isJunior,
                    isNext: isNext ? isNext : false,
                    items: JSON.stringify(items.map(({type, value, planType}) => ({type, value, planType}))),
                    ...items.length && {planType: items[0].planType},
                    ...items.length && {type: items[0].type},
                    ...items.length && {value: items[0].value}
                  }
                };

                this.storage.local.push(
                  STORAGE_SESSION,
                  encodeCookie({
                    ...decodeCookie(this.storage.local.pull(STORAGE_SESSION) as string),
                    items: JSON.stringify(items.map(({type, value, planType}) => ({type, value, planType}))),
                  })
                );

                this.storage.local.push(
                  STORAGE_DEV,
                  encodeCookie(storage)
                );

                patchState({
                  items,
                  token
                });
              })
            );
        })
      );
  }

  @Action(AwsSwitchItem)
  awsSwitchItem({dispatch, patchState}: StateContext<AwsAuthorizationStateModel>, {item}: AwsSwitchItem) {
    const {value, type, planType} = item;
    const storage = {
      ...decodeCookie(this.storage.local.pull(STORAGE_DEV) as string),
      ...{
        type,
        value,
        planType
      }
    };

    this.storage.local.push(
      STORAGE_DEV,
      encodeCookie(storage)
    );

    patchState({
      focusedItem: {type, value, planType}
    });
  }
}
