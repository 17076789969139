import {Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {SessionState, WindowService} from '@myvf/core';
import { transition, trigger } from '@angular/animations';
import { slideLeft, slideRight } from '../digital-container/shared/digital-container-slide-animation';
import { NavigationEnd, Router } from '@angular/router';
import { DigitalContainerService } from '../digital-container/shared/digital-container.service';

@Component({
  selector: 'myvf-container',
  template: `
    <div class="myvf-container-wrapper" [class.pages-in-bottom]="isBottom" [class.ios]="isIos">
      <router-outlet name="primary"></router-outlet>
      <div [@routerAnimations]="prepareRouteTransition()" [ngClass]="{'slide-right' : slideRightLoader, 'slide-left' : slideLeftLoader}">
        <router-outlet name="digitalContainer" #outlet="outlet"></router-outlet>
      </div>
      <myvf-loader></myvf-loader>
      <myvf-notification></myvf-notification>
      <myvf-sticky></myvf-sticky>
      <myvf-otp></myvf-otp>
      <myvf-custom-error></myvf-custom-error>
    </div>`,
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('routerAnimations', [
      transition('left => right, right2 => right, left2 => right', slideRight),
      transition('right => left, right2 => left, left2 => left', slideLeft)
    ])
  ]
})
export class ContainerComponent implements OnInit {
  public isBottom: boolean;
  public isIos: boolean;
  previousRoute: string;
  nextRoute: string;
  animationDirection: string;
  slideRightLoader: boolean;
  slideLeftLoader: boolean;

  constructor(
    private store: Store,
    private window: WindowService,
    public digitalContainerService: DigitalContainerService,
    public router: Router
  ) {
    this.digitalContainerService.event$.subscribe((event) => {
      //check if there is a previous route (means that the route is changing and we are not landing for the first time in digitalContainer)
      //if exist, check if the route is changed and, if yes, perform the animation.
      //After all it always set the previous route for the next tab click
      if(this.previousRoute && event !== this.previousRoute){
        document.body.classList.add('no-scroll');
        this.nextRoute = event;
        this.animationDirection = this.digitalContainerService.getAnimationDirection(this.previousRoute, this.nextRoute, this.animationDirection);
        if(this.animationDirection === 'right' || this.animationDirection === 'right2'){
          this.slideRightLoader = true;
        } else if(this.animationDirection === 'left' || this.animationDirection === 'left2'){
          this.slideLeftLoader = true;
        }
      }
      this.previousRoute = event;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onActivate();
      }
    });
  }

  ngOnInit(): void {
    const {isOverlay} = this.store.selectSnapshot(SessionState);
    this.isBottom = typeof isOverlay !== 'undefined' ? !isOverlay : false;
    this.isIos = this.window.isiOS();
  }

  prepareRouteTransition(){
    return this.animationDirection;
  }

  onActivate(){
    this.slideLeftLoader = false;
    this.slideRightLoader = false;
    document.body.classList.remove('no-scroll');
  }


}
