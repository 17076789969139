import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[stickyTitle]' // eslint-disable-line @angular-eslint/directive-selector
})
export class StickyTitleDirective {
  constructor(
    public element: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.element.nativeElement, 'myvf-sticky-title');
  }
}
