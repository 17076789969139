import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { CardBodyComponent } from './card-body/card-body.component';
import { CardBodyHeaderComponent } from './card-body-header/card-body-header.component';
import { CardBodyTextComponent } from './card-body-text/card-body-text.component';
import { CardBodyFooterComponent } from './card-body-footer/card-body-footer.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CardComponent,
    CardBodyComponent,
    CardBodyHeaderComponent,
    CardBodyTextComponent,
    CardBodyFooterComponent
  ],
  exports: [
    CardComponent,
    CardBodyComponent,
    CardBodyHeaderComponent,
    CardBodyTextComponent,
    CardBodyFooterComponent

  ]
})
export class CardModule { }
