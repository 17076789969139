import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ApiModule} from './api.module';
import {HEADER_API} from '../constants';
import {CORE_CONFIGURATION} from '../configuration';
import {
  CustomErrorPageResponse,
  CustomErrorResponse,
  MaintenanceErrorResponse,
  SessionExpiredErrorResponse
} from './custom-error-response.type';
import {CustomErrorService} from './custom-error.service';
import {CustomErrorCodes} from '../../shared/errors/custom-error/custom-error-codes';

@Injectable({
  providedIn: ApiModule,
  deps: [
    HttpClient,
    CustomErrorService,
    CORE_CONFIGURATION
  ]
})
export class BaseApiService {
  private _header: { [p: string]: string | string[] } = {
    [HEADER_API]: 'true'
  };

  private _initial_header = {...this._header};

  constructor(
    private http: HttpClient,
    private customErrorService: CustomErrorService,
    @Inject(CORE_CONFIGURATION) public config
  ) {
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get headers(): { [p: string]: string | string[] } {
    return this._header;
  }

  header(key: string, value: string | string[]) {
    this._header[key] = value;

    return this;
  }

  get(
    path: string,
    params: HttpParams = new HttpParams(),
    // eslint-disable-next-line
    responseType?: 'arrayBuffer' | 'blob' | 'text' | 'json',
    hideError: boolean = false
  ): Observable<any> {
    return this.http
      .get(`${this.config.apiHost}${path}`, {
        params,
        reportProgress: true,
        headers: new HttpHeaders(this.headers),
        ...({responseType} as any || null)
      })
      .pipe(
        this.catchCustomError(),
        catchError((error) => {
          return !hideError ? this.formatErrors(error) : of(error);
        }),
        tap(() => this._header = {...this._initial_header})
      );
  }

  // Custom apiHost
  get2(
    apiHost: string,
    path: string,
    params: HttpParams = new HttpParams(),
    // eslint-disable-next-line
    responseType?: 'arrayBuffer' | 'blob' | 'text' | 'json'
  ): Observable<any> {
    return this.http
      .get(`${apiHost}${path}`, {
        params,
        reportProgress: true,
        headers: new HttpHeaders(this.headers),
        ...({responseType} as any || null)
      })
      .pipe(
        this.catchCustomError(),
        catchError(this.formatErrors),
        tap(() => this._header = {...this._initial_header})
      );
  }

  post(
    path: string,
    body: object = {},
    hideError: boolean = false
  ): Observable<any> {
    return this.http
      .post(`${this.config.apiHost}${path}`, body, {reportProgress: true, headers: new HttpHeaders(this.headers)})
      .pipe(
        this.catchCustomError(),
        catchError((error) => {
          return !hideError ? this.formatErrors(error) : of(error);
        }),
        tap(() => this._header = {...this._initial_header})
      );
  }

  // For fake payment page MM
  post2(path: string, body: any = {}, options): Observable<any> {
    return this.http
      .post(path, body, options)
      .pipe(
        this.catchCustomError(),
        catchError(this.formatErrors),
        tap(() => this._header = {...this._initial_header})
      );
  }

  // Observe headers
  postObserve(path: string, body: any = {}): Observable<any> {
    return this.http
      .post(path, body, {observe: 'response', reportProgress: true, headers: new HttpHeaders(this.headers)})
      .pipe(
        this.catchCustomError(),
        catchError(this.formatErrors),
        tap(() => this._header = {...this._initial_header})
      );
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${this.config.apiHost}${path}`, body, {reportProgress: true, headers: new HttpHeaders(this.headers)})
      .pipe(
        this.catchCustomError(),
        catchError(this.formatErrors),
        tap(() => this._header = {...this._initial_header})
      );
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(`${this.config.apiHost}${path}`, {reportProgress: true, headers: new HttpHeaders(this.headers)})
      .pipe(
        this.catchCustomError(),
        catchError(this.formatErrors),
        tap(() => this._header = {...this._initial_header})
      );
  }

  catchCustomError() {
    const parser = this.customErrorService.parse;

    return (source: Observable<any>) =>
      new Observable(observer => {
        return source.subscribe({
          next(response) {
            if (response.code) {
              if (response.code >= 500 && response.code < 600) {
                observer.error(
                  new CustomErrorResponse(
                    parser({code: response.code})
                  )
                );
              } else if (response.code === 411) {
                observer.error(
                  new MaintenanceErrorResponse(
                    parser({code: response.code, message: response.message})
                  )
                );
              } else if (response.code === 403 || response.code === 401) {
                observer.error(
                  new SessionExpiredErrorResponse(
                    parser({code: response.code})
                  )
                );
              } else if (response.code in CustomErrorCodes) {
                observer.error(
                  new CustomErrorPageResponse(
                    parser({code: response.code})
                  )
                );
              } else {
                observer.next(response);
              }
            } else {
              observer.next(response);
            }
          },
          error(e) {
            observer.error(e);
          },
          complete() {
            observer.complete();
          }
        });
      });
  }
}
