import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyvfUiModule} from '@myvf/ui';
import {NotificationComponent} from './notification/notification.component';

@NgModule({
  imports: [
    CommonModule,
    MyvfUiModule
  ],
  declarations: [
    NotificationComponent
  ],
  exports: [
    NotificationComponent
  ]
})
export class NotificationModule {
}
