import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorService {
  public parse(init: { code?: number, message?: any }): { code: number, message: string } {
    return {
      code: init.code || 0,
      message: init.message || null
    };
  }
}
