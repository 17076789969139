import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomErrorComponent} from './custom-error/custom-error.component';

@NgModule({
  declarations: [
    CustomErrorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomErrorComponent
  ]
})
export class CustomErrorModule {
}
