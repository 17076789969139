import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {ApiModule} from './api.module';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class NewsApiService {
  constructor(
    private http: BaseApiService
  ) {
  }

  getNews(categories: [], pages: [], noLoader = false): Observable<any> {
    let params = new HttpParams();
    if (pages?.length) {
      pages.forEach(v => params = params.append('pages', v) );
    }
    if (categories?.length) {
      categories.forEach(v => params = params.append('categories', v) );
    }
    if (noLoader) { params = params.set('noLoader', 'true'); }

    return this.http.get('/api/journeynews/v1/news', params);
  }

  getNewsDemoFromAEM(categories: any): Observable<any> {
    return this.http.get2(this.http.config?.apiHostAEM, `/content/myvf/news/list.news.list.${categories[0]}.1.json`);
  }
}
