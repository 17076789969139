import {Inject, Injectable} from '@angular/core';
import {DOCUMENT, Location} from '@angular/common';
import {WindowService} from '../common/window.service';

@Injectable({
  providedIn: 'root',
  deps: [
    DOCUMENT,
    Location,
    WindowService
  ]
})
export class LocationService {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private location: Location,
    private window: WindowService
  ) {
  }

  // TODO: Android fa il doppio decode dell ancora solo su i parametri, convincere Android a levare sta porcata.
  encodeAnchorAndroid(url: string) {
    const ap = this.window.encodeQueryParams(this.window.decodeQueryParams(url));
    return `${this.window.encode(url.split('?')[0])}?${this.window.encode(this.window.encode(ap))}`;
  }

  /**
   * Transform a relative url into an absolute one (essential for client's url scheme aka anchors)
   * @param commands array of relative path substring
   * @param queryParams query params to join into url
   */
  prepareExternalUrl(commands: any[], queryParams: object = {}): string {
    let query = '';
    const params = new URLSearchParams();
    const url = commands.reduce(
      (acc, curr) => Location.joinWithSlash(acc, curr),
      this.document.location.origin
    );

    Object
      .keys(queryParams)
      .forEach(p => {
        params.append(p, queryParams[p]);
      });

    if (Object.keys(queryParams).length) {
      query = `?${params.toString()}`;
    }

    return `${url}${query}`;
  }

  prepareAnchor(url: string, params: object = {}, type: string = 'hybrid', baseParams: object = {'x-a': 'all', 'x-t': 'all'}): string {
    const encUrl = this.window.isAndroid() ? this.encodeAnchorAndroid(url) : this.window.encode(url);

    const baseAnchor = `myvfit://view/${type}`;
    const anchorParams = {...baseParams, ...params};

    return (/^https?:\/\//i.test(url)) ?
      `${baseAnchor}?pageURL=${encUrl}&${this.window.encodeQueryParams(anchorParams)}` :
      `${baseAnchor}?pageURL=${this.document.location.origin}${encUrl}&${this.window.encodeQueryParams(anchorParams)}`;

  }
}
