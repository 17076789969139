import {Injectable} from '@angular/core';
import {CustomErrorService} from '../../core/api/custom-error.service';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorSharedService implements CustomErrorService {
  constructor() {
  }

  parse(init: { code?: number, message?: string }): { code: number, message: string } {
    return {
      code: init.code || 0,
      message: init.message || 'Servizio non disponibile'
    };
  }
}
