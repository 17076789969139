import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorService {
  public events: Observable<any> = new Subject<any>();

  constructor() {
  }

  /**
   * open custom error page
   * @param customCode custom code from api
   */
  public open(customCode: number): void {
    (this.events as Subject<any>).next({show: true, customCode});
  }
}
