import {Inject, Injectable, InjectionToken} from '@angular/core';

export const Window = new InjectionToken(
  'Window',
  {
    providedIn: 'root',
    factory: () => window
  }
);

@Injectable({
  providedIn: 'root',
  deps: [
    Window
  ]
})
export class WindowService {
  constructor(
    @Inject(Window) private nativeWindow: Window
  ) {
  }

  get native(): any {
    return this.nativeWindow;
  }

  go(url: string): void {
    this.native.location.assign(url);
  }

  href(url: string): void {
    this.native.location.href = url;
  }

  reload(): void {
    this.native.location.reload();
  }

  getLocationParams(href: string = this.native.location.href): object {
    return this.decodeQueryParams(href);
  }

  encodeQueryParams(params: object): string {
    return Object.keys(params).map(k => `${k}=${params[k]}`).join('&');
  }

  decodeQueryParams(url: string): any {
    return (url.match(/([^?=&]+)(=([^&]*))/g) || [])
      .reduce(
        (a, v) => {
          const key = v.slice(0, v.indexOf('='));
          const value = v.slice(v.indexOf('=') + 1);

          a[key] = value;

          return a;
        },
        {}
      );
  }

  encode(str: string): string {
    return this.native.encodeURIComponent(str.toString());
  }

  decode(str: string): string {
    return this.native.decodeURIComponent(str.toString());
  }

  getCoordinates(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.native.navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error)
      );
    });
  }

  isAndroid() {
    return this.native.navigator.userAgent.match(/Android/i);
  }

  isiOS() {
    return this.native.navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  isHuawei() {
    return this.native.navigator.userAgent.match(/huawei|honor/i);
  }

  isWebView(){
    return this.native.BWB_JS.isWebView;
  }

}
