import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {ApiModule} from './api.module';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class LandlineApiService {
  constructor(
    private http: BaseApiService
  ) {
  }

  details(number: string) {
    return this.http.get(`/api/v4/landline/${number}`);
  }

  setConsents(number: string, data: any): Observable<any> {
    return this.http.post(`/api/v3/landline/${number}/agreements`, data);
  }

  getConsents(number: string, isSetup: string): Observable<any> {
    const params = new HttpParams()
      .set('isSetup', isSetup);

    return this.http.get(`/api/v3/landline/${number}/agreements`, params);
  }
}
