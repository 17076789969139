import {Action, State, StateContext} from '@ngxs/store';
import {GetFromBridge, GetFromMock, GetFromStorage} from './session.action';
import {SessionStateModel} from './session.model';
import {AuthState} from '../auth/auth.state';
import {BridgeService} from '../bridge/bridge.service';
import {UserState} from '../user/user.state';
import {decodeCookie, decodeResponseBridge, omit, pick, renameProperty} from '../util.class';
import {AuthStateModel} from '../auth/auth.model';
import {UserStateModel} from '../user/user.model';
import {StorageService} from '../storage/storage.service';
import { AWS_FIRST_VERSION, STORAGE_DEV, STORAGE_SESSION } from '../constants';
import { Inject, Injectable } from '@angular/core';
import { CORE_CONFIGURATION } from '../configuration';
import { environment } from '../../../environments/environment';
import semver from 'semver';

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    appPlatform: '',
    appVersion: '',
    androidServices: '',
    auth: null,
    osVersion: '',
    profile: '',
    status: '',
    type: '',
    type_sim: '',
    user: null,
    value: '',
    userAgent: '',
    role: null,
  },
  children: [
    AuthState,
    UserState
  ]
})
@Injectable()
export class SessionState {
  constructor(
    @Inject(CORE_CONFIGURATION) private config,
    private bridge: BridgeService,
    private storage: StorageService
  ) {
  }

  @Action(GetFromBridge)
  async getFromBridge({ setState }: StateContext<SessionStateModel>) {
    let session: any = await this.bridge.getSession();
    session = renameProperty(session, 'fiscalcode', 'fiscalCode');

    setState(this.setSession(decodeResponseBridge(session)));
  }

  @Action(GetFromStorage)
  getFromStorage({ setState }: StateContext<SessionStateModel>) {
    const devStorage = this.storage.local.pull(STORAGE_DEV);

    if (devStorage) {
      const session = decodeCookie(devStorage as string);

      setState(this.setSession(session));
    } else {
      setState({
        auth: {
          installation_id: '',
          session_id: '',
          token: ''
        },
        appVersion: '13.3.0',
        value: '',
        userAgent: 'MyVodafone/android/s/8.0/13.2.0/5/custom/"Huawey"/"MH-9"'
      });
    }
  }

  @Action(GetFromMock)
  getFromMock({ setState }: StateContext<SessionStateModel>) {
    const session: SessionStateModel = {
      appPlatform: 'Mock',
      appVersion: '13.3.0',
      androidServices: 'HMS',
      auth: {
        installation_id: '0',
        mid: '0',
        pbo: '0',
        secret_key: '0',
        session_id: '0',
        token: '0'
      },
      osVersion: '0',
      profile: 'A00',
      status: 'authenticated',
      type: 'sim',
      type_sim: 'ricaricabile',
      role: '',
      user: {
        firstname: 'Test',
        username: 'test_user',
        email: 'test@user.it',
        fiscalCode: 'TSTUSR00A00A000A',
        isNext: true
      },
      value: '3333333333',
      userAgent: 'MyVodafone/android/s/8.0/13.3.0/5/custom/"Huawey"/"MH-9"',
      customerId: '600477848',
      contactId: '220321803653',
      planType: 'prepaid',
      items: [{
        type: 'sim',
        value: '3333333333',
        planType: 'prepaid'
      },{
        type: 'sim',
        value: '4444444444',
        planType: 'prepaid'
      },{
        type: 'landline',
        value: '5555555555',
        planType: 'postpaid'
      }],
      initiative: '',
      visitor_state_multi_factor: 'VALIDATED'
    };
    this.changeCoreConfiguration(session.appVersion);

    setState(session);
  }

  private setSession(session) {
    const sessionStorage: any = decodeCookie(this.storage.local.pull(STORAGE_SESSION) as string);

    const {
      installation_id,
      session_id,
      token,
      mid,
      secret_key,
      customerUserAgent: userAgent,
      pbo
    }: any = {
      ...sessionStorage,
      ...session,
      installation_id: sessionStorage.installation_id ? sessionStorage.installation_id : session.installationId,
      session_id: sessionStorage.session_id ? sessionStorage.session_id : session.sessionId,
      secret_key: sessionStorage.secret_key ? sessionStorage.secret_key : session.secretKey
    };

    const auth: AuthStateModel = {
      ...installation_id && {installation_id},
      ...session_id && {session_id},
      ...token && {token},
      ...mid && {mid},
      ...secret_key && {secret_key},
      ...pbo && {pbo},
    } as AuthStateModel;

    const user: UserStateModel = pick(session, [
      'username',
      'firstname',
      'lastname',
      'fiscalCode',
      'email',
      'isNext',
      'isJunior'
    ]);

    let state: SessionStateModel = omit(session, [
      'email',
      'firstname',
      'lastname',
      'username',
      'fiscalCode',
      'installationId',
      'sessionId',
      'mid',
      'secretKey',
      'token',
      'pbo',
      'customerUserAgent',
      'isNext',
      'isJunior'
    ]) as SessionStateModel;

    const planType = ((session.items || []).find(i => i.value === session.value) ?? {}).planType;
    state = {
      ...state,
      ...planType && {planType}
    };

    this.changeCoreConfiguration(session.appVersion);

    return {
      ...{
        ...state,
        userAgent
      },
      auth,
      user
    };
  }

  private changeCoreConfiguration(appVersion) {
    if (semver.satisfies(appVersion, `< ${AWS_FIRST_VERSION}`)) {
      this.config.environment = environment.onPremises.name;
      this.config.apiHost = environment.onPremises.api;
    }
  }
}
