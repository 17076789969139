import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {StickyTitleDirective} from './sticky-title.directive';
import {StickyBodyDirective} from './sticky-body.directive';
import {StickyHostDirective} from './sticky-host.directive';

@Component({
  selector: 'myvf-ui-sticky-feed',
  templateUrl: './sticky-feed.component.html',
  styleUrls: ['./sticky-feed.component.scss']
})
export class StickyFeedComponent implements OnInit, AfterContentInit, AfterContentChecked {
  @ContentChild(StickyTitleDirective, {static: false}) stickyTitle: StickyTitleDirective;
  @ContentChild(StickyBodyDirective, {static: false}) stickyBody: StickyBodyDirective;
  @ContentChild(StickyHostDirective, {static: false}) stickyHost: StickyHostDirective;

  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('header', { static: true }) headerInfo: ElementRef;

  @Input() type: 'success' | 'warning' | 'error';

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.renderer.addClass(this.element.nativeElement, `myvf-sticky-${this.type}`);
  }

  ngAfterContentInit(): void {
    this.createHeader();
  }

  ngAfterContentChecked(): void {
    this.createHeader();
  }

  createHeader() {
    if (this.stickyTitle && this.headerInfo) {
      this.renderer.appendChild(this.headerInfo.nativeElement, this.stickyTitle.element.nativeElement);
    }

    if (this.stickyBody && this.headerInfo) {
      this.renderer.appendChild(this.headerInfo.nativeElement, this.stickyBody.element.nativeElement);
    }
  }
}
