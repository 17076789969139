export class GetFromBridge {
  static readonly type = '[Session] get from bridge';
}

export class GetFromStorage {
  static readonly type = '[Session] get from storage';
}

export class GetFromMock {
  static readonly type = '[Session] get from mock';
}
