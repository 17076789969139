import {Component, Input} from '@angular/core';

@Component({
  selector: 'myvf-ui-sticky-feed-icon',
  template: `
    <div myvf-ui-icon-success *ngIf="icon === 'success'"><span class="path1"></span><span class="path2"></span></div>
    <div myvf-ui-icon-warning *ngIf="icon === 'warning'"><span class="path1"></span><span class="path2"></span></div>
    <div myvf-ui-icon-error *ngIf="icon === 'error'"><span class="path1"></span><span class="path2"></span></div>`
})
export class StickyFeedIconComponent {
  @Input() icon: string;
}
