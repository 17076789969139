import {Injectable, Injector} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {CookieStorageService} from './cookie-storage.service';

@Injectable({
  providedIn: 'root',
  deps: [
    Injector
  ]
})
export class StorageService {
  private _localStorageService: LocalStorageService;
  private _cookieStorageService: CookieStorageService;

  constructor(private injector: Injector) {
  }

  public get local(): LocalStorageService {
    if (!this._localStorageService) {
      this._localStorageService = this.injector.get(LocalStorageService);
    }

    return this._localStorageService;
  }

  public get cookie(): CookieStorageService {
    if (!this._cookieStorageService) {
      this._cookieStorageService = this.injector.get(CookieStorageService);
    }

    return this._cookieStorageService;
  }
}
