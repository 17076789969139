import {Directive, ElementRef, AfterViewInit} from '@angular/core';

const ATTRIBUTES = [
  'myvf-ui-icon-arrow-right',
  'myvf-ui-icon-file',
  'myvf-ui-icon-arrow',
  'myvf-ui-icon-calendar',
  'myvf-ui-icon-library',
  'myvf-ui-icon-credit-card',
  'myvf-ui-icon-sim',
  'myvf-ui-icon-spinner',
  'myvf-ui-icon-document',
  'myvf-ui-icon-success',
  'myvf-ui-icon-error',
  'myvf-ui-icon-warning',
  'myvf-ui-icon-locate'
];

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `
  [myvf-ui-icon-arrow-right],
  [myvf-ui-icon-file],
  [myvf-ui-icon-arrow],
  [myvf-ui-icon-calendar],
  [myvf-ui-icon-library],
  [myvf-ui-icon-credit-card],
  [myvf-ui-icon-sim],
  [myvf-ui-icon-spinner],
  [myvf-ui-icon-document],
  [myvf-ui-icon-success],
  [myvf-ui-icon-error],
  [myvf-ui-icon-warning],
  [myvf-ui-icon-locate]`
})
export class IconDirective implements AfterViewInit {
  private _elementRef: ElementRef;

  constructor(private elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  ngAfterViewInit() {
    for (const attr of ATTRIBUTES) {
      if (this._hasAttribute(attr)) {
        this.elementRef.nativeElement.classList.add(attr.replace('myvf-ui-icon-', 'myvf-icon-'));
      }
    }
  }

  private _hasAttribute(...attributes: string[]): boolean {
    return attributes.some(attribute => this._getElement().hasAttribute(attribute));
  }

  private _getElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }
}
