import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private queue = 0;

  private loaderObservable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  spinner: Observable<boolean> = this.loaderObservable$.asObservable();

  show() {
    this.queue = this.queue + 1;
    this.loaderObservable$.next(true);
  }

  hide() {
    this.queue = this.queue <= 0 ? 0 : this.queue - 1;

    if (this.queue === 0) {
      this.loaderObservable$.next(false);
    }
  }
}
