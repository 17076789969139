<div class="myvf-sticky-overlay">
  <div class="myvf-sticky" #dialog>

    <div class="myvf-sticky-header">
      <div class="myvf-sticky-icon">
        <myvf-ui-sticky-feed-icon [icon]="type"></myvf-ui-sticky-feed-icon>
      </div>
      <div #header class="myvf-sticky-info"></div>
    </div>

    <ng-content></ng-content>
  </div>
</div>
