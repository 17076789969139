import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { AEM_CONFIGURATION, AemOptions } from './configuration';
import { AemState } from './shared/aem.state';
import { AemSharedModule } from './shared/aem-shared.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forFeature([AemState]),
    AemSharedModule,
  ],
})
export class AemModule {
  constructor(@Optional() @SkipSelf() parentModule: AemModule) {
    if (parentModule) {
      throw new Error('AemModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: AemOptions): ModuleWithProviders<AemModule> {
    return {
      ngModule: AemModule,
      providers: [
        {
          provide: AEM_CONFIGURATION,
          useValue: config,
        },
      ],
    };
  }
}
