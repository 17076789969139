import { Injectable, Injector } from '@angular/core';
import { AppApiService } from './app-api.service';
import { AuthApiService } from './auth-api.service';
import { ClientApiService } from './client-api.service';
import { DeviceApiService } from './device-api.service';
import { SessionApiService } from './session-api.service';
import { SimApiService } from './sim-api.service';
import { LandlineApiService } from './landline-api.service';
import { AnchorsApiService } from './anchors-api.service';
import { OneTimePasswordApiService } from './one-time-password-api.service';
import { MiscApiService } from './misc-api.service';
import { AgreementsApiService } from './agreements-api.service';
import { CatalogApiService } from './catalog-api.service';
import { NewsApiService } from './news-api.service';
import { ItemsApiService } from './items-api.service';
import { MopApiService } from './mop-api.service';
import { FamilyApiService } from './family-api.service';
import {ExternalservicesApiService} from './externalservices-api.service';

@Injectable({
  providedIn: 'root',
  deps: [
    Injector
  ]
})
export class ApiService {
  private authApiService: AuthApiService;
  private appApiService: AppApiService;
  private clientApiService: ClientApiService;
  private newsApiService: NewsApiService;
  private deviceApiService: DeviceApiService;
  private externalservicesApiService: ExternalservicesApiService;
  private sessionApiService: SessionApiService;
  private simApiService: SimApiService;
  private landlineApiService: LandlineApiService;
  private anchorsApiService: AnchorsApiService;
  private oneTimePasswordApiService: OneTimePasswordApiService;
  private miscApiService: MiscApiService;
  private agreementsApiService: AgreementsApiService;
  private catalogApiService: CatalogApiService;
  private itemsApiService: ItemsApiService;
  private mopApiService: MopApiService;
  private familyApiService: FamilyApiService;

  constructor(
    private injector: Injector
  ) {
  }

  public get auth(): AuthApiService {
    if (!this.authApiService) {
      this.authApiService = this.injector.get(AuthApiService);
    }

    return this.authApiService;
  }

  public get app(): AppApiService {
    if (!this.appApiService) {
      this.appApiService = this.injector.get(AppApiService);
    }

    return this.appApiService;
  }

  public get client(): ClientApiService {
    if (!this.clientApiService) {
      this.clientApiService = this.injector.get(ClientApiService);
    }

    return this.clientApiService;
  }


  public get landline(): LandlineApiService {
    if (!this.landlineApiService) {
      this.landlineApiService = this.injector.get(LandlineApiService);
    }

    return this.landlineApiService;
  }

  public get news(): NewsApiService {
    if (!this.newsApiService) {
      this.newsApiService = this.injector.get(NewsApiService);
    }

    return this.newsApiService;
  }

  public get device(): DeviceApiService {
    if (!this.deviceApiService) {
      this.deviceApiService = this.injector.get(DeviceApiService);
    }

    return this.deviceApiService;
  }

  public get awsDevice(): DeviceApiService {
    if (!this.deviceApiService) {
      this.deviceApiService = this.injector.get(DeviceApiService);
    }

    return this.deviceApiService;
  }

  public get session(): SessionApiService {
    if (!this.sessionApiService) {
      this.sessionApiService = this.injector.get(SessionApiService);
    }

    return this.sessionApiService;
  }

  public get sim(): SimApiService {
    if (!this.simApiService) {
      this.simApiService = this.injector.get(SimApiService);
    }

    return this.simApiService;
  }

  public get catalog(): CatalogApiService {
    if (!this.catalogApiService) {
      this.catalogApiService = this.injector.get(CatalogApiService);
    }

    return this.catalogApiService;
  }

  public get anchors(): AnchorsApiService {
    if (!this.anchorsApiService) {
      this.anchorsApiService = this.injector.get(AnchorsApiService);
    }

    return this.anchorsApiService;
  }

  public get oneTimePassword(): OneTimePasswordApiService {
    if (!this.oneTimePasswordApiService) {
      this.oneTimePasswordApiService = this.injector.get(OneTimePasswordApiService);
    }

    return this.oneTimePasswordApiService;
  }

  public get misc(): MiscApiService {
    if (!this.miscApiService) {
      this.miscApiService = this.injector.get(MiscApiService);
    }

    return this.miscApiService;
  }

  public get agreements(): AgreementsApiService {
    if (!this.agreementsApiService) {
      this.agreementsApiService = this.injector.get(AgreementsApiService);
    }

    return this.agreementsApiService;
  }

  public get items(): ItemsApiService {
    if (!this.itemsApiService) {
      this.itemsApiService = this.injector.get(ItemsApiService);
    }

    return this.itemsApiService;
  }

  public get mop(): MopApiService {
    if (!this.mopApiService) {
      this.mopApiService = this.injector.get(MopApiService);
    }

    return this.mopApiService;
  }

  public get externalservices(): ExternalservicesApiService {
    if (!this.externalservicesApiService) {
      this.externalservicesApiService = this.injector.get(ExternalservicesApiService);
    }

    return this.externalservicesApiService;
  }

  public get family(): FamilyApiService {
    if (!this.familyApiService) {
      this.familyApiService = this.injector.get(FamilyApiService);
    }

    return this.familyApiService;
  }
}
