import {Injectable} from '@angular/core';
import {StorageInterface} from './storage.interface';
import {WindowService} from '../common/window.service';
import {StorageModule} from './storage.module';

@Injectable({
  providedIn: StorageModule,
  deps: [
    WindowService
  ]
})
export class LocalStorageService implements StorageInterface {

  constructor(private window: WindowService) {
  }

  has(key: string): boolean {
    return this.window.native.localStorage.getItem(key) !== null;
  }

  pull(key: string): string | object | any[] {
    const value = this.window.native.localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  push(key: string, value: string | object | any[]): void {
    const v: string = Array.isArray(value) || typeof value === 'object' ? JSON.stringify(value) : value;

    this.window.native.localStorage.setItem(key, v);
  }

  delete(key: string): void {
    this.window.native.localStorage.removeItem(key);
  }
}
