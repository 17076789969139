import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {FakeApiInterceptorService} from './fake-api-interceptor.service';

@NgModule({
  imports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FakeApiInterceptorService,
      multi: true
    }
  ],
  declarations: []
})
export class ApiModule {
}
