import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {Store} from '@ngxs/store';
import {BridgeManagerModule} from './bridge-manager.module';
import {BridgeManagerService} from './bridge-manager.service';
import {GetFromBridge, GetFromMock, GetFromStorage} from '../session/session.action';
import {CORE_CONFIGURATION, CoreOptions} from '../configuration';

@NgModule({
  imports: [
    BridgeManagerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (
        bridge: BridgeManagerService,
        store: Store,
        injector: Injector
      ) => () =>
        bridge
          .initializer()
          .then(
            () => {
              const config: CoreOptions = injector.get(CORE_CONFIGURATION);

              if (config.isInEditor) {
                return store.dispatch(new GetFromMock()).toPromise();
              }

              if (bridge.isWebView()) {
                return store.dispatch(new GetFromBridge()).toPromise();
              } else {
                return store.dispatch(new GetFromStorage()).toPromise();
              }
            }
          ),
      multi: true,
      deps: [
        BridgeManagerService,
        Store,
        Injector
      ]
    }
  ],
  declarations: []
})
export class BridgeModule {
}
