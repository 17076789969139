import {Injectable} from '@angular/core';
import {BridgeManagerModule} from './bridge-manager.module';
import {BridgeConnectorService} from './bridge-connector.service';
import {BridgeApiService} from './bridge-api.service';
import {WindowService} from '../common/window.service';
import {is} from '../util.class';

export interface BwBJsInterface {
  isWebView: boolean;
  execute: Function;
}

@Injectable({
  providedIn: BridgeManagerModule,
  deps: [
    WindowService,
    BridgeConnectorService,
    BridgeApiService
  ]
})
export class BridgeManagerService {
  private bwbjs: BwBJsInterface;
  private key = 'callback';

  isReady = false;

  constructor(
    private window: WindowService,
    private connector: BridgeConnectorService,
    private api: BridgeApiService
  ) {
  }

  isWebView(): boolean {
    return this.connector.isWebView;
  }

  initializer() {
    return this.connector
      .toPromise()
      .then((bridge: BwBJsInterface) => {
        this.isReady = true;
        this.bwbjs = bridge;

        Object.assign(this.window.native, BridgeApiService.toObject(this.api));
      });
  }

  private getCallbackName(name: string): string {
    return [name, this.key, Date.now()].join('_');
  }

  private serialize(params: object): string {
    return Object
      .keys(params)
      .map(key => {
        let item = params[key];

        if (is(Array, item) || is(Object, item)) {
          item = JSON.stringify(item);
        }

        return `${key}=${encodeURIComponent(item)}`;
      }).join('&');
  }

  call(name: string, params: any, callbackName: string = this.getCallbackName(name)): Promise<any> {
    const cbName = callbackName;
    const cbParams = this.serialize(params);

    this.bwbjs.execute(name, cbParams, cbName);

    return new Promise<any>(resolve => {
      this.window.native[cbName] = (...args) => {
        delete this.window.native[cbName];

        if (args.length === 0) {
          resolve(null);
        }

        const par = args.length === 1 ? args[0] : args;

        resolve(par);
      };
    });
  }
}
