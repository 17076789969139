import {Observable, Subject} from 'rxjs';
import {Injectable, NgZone} from '@angular/core';
import {BridgeManagerModule} from './bridge-manager.module';
import {BridgeApiEvent, DataFromOverlay, IsShaking, OnPageFocused, SendContact} from './bridge.model';
import {decodeResponseBridge} from '../util.class';

@Injectable({
  providedIn: BridgeManagerModule,
  deps: [
    NgZone
  ]
})
export class BridgeApiService {

  readonly events: Observable<BridgeApiEvent> = new Subject<BridgeApiEvent>();

  constructor(
    private zone: NgZone
  ) {
  }

  public static toObject(instance): object {
    return Object.getOwnPropertyNames(this.prototype).reduce(
      (acc, curr) => this.prototype.hasOwnProperty(curr) && {...acc, [curr]: this.prototype[curr].bind(instance)},
      {},
    );
  }

  isShaking(): void {
    this.zone.run(
      () => (this.events as Subject<BridgeApiEvent>).next(
        new IsShaking()
      )
    );
  }

  reloadFromOverlay(): void {
    console.log('reloadFromOverlay called!');
  }

  sendContact(contact): void {
    this.zone.run(
      () => (this.events as Subject<BridgeApiEvent>).next(
        new SendContact(decodeResponseBridge(contact) as { label: string, msisdn: string })
      )
    );
  }

  dataFromOverlay(params): void {
    this.zone.run(
      () => (this.events as Subject<BridgeApiEvent>).next(
        new DataFromOverlay(decodeResponseBridge(params)))
    );
  }

  onPageFocused():void{
    this.zone.run(
      () => (this.events as Subject<BridgeApiEvent>).next(
        new OnPageFocused())
    );
  }
}
