import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AEM_CONFIGURATION } from '../configuration';
import { AemSharedModule } from './aem-shared.module';

@Injectable({
  providedIn: AemSharedModule,
})
export class AemModelClientService {
  constructor(
    private client: HttpClient,
    @Inject(AEM_CONFIGURATION) private config,
  ) {
  }

  fetch(modelPath: any): Promise<any> {
    if (!modelPath) {
      return Promise.reject(`Fetching model rejected for path: ${modelPath}`);
    }

    return this.client
      .get(`${this.config.host}${modelPath}`)
      .toPromise()
      .catch(e => ({}));
  }
}
