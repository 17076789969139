import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {ApiModule} from './api.module';
import {map} from 'rxjs/operators';
import { AWS_FIRST_VERSION, CORE_CONFIGURATION, getFinalResponseData, SessionState } from '@myvf/core';
import {Store} from '@ngxs/store';
import semver from 'semver';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class AuthApiService {
  constructor(
    private http: BaseApiService,
    private store: Store,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  guest() {
    return this.http.post('/api/v3/auth/guest');
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post('/api/v3/auth/login', {username, password});
  }

  loginByUsernameAndPassword(username: string, password: string): Observable<any> {
    return this.http.postObserve(`/${this.config.environment}/authorization/v1/loginByUsernameAndPassword`, {username, password});
  }

  /**
   * get isJunior
   */
  isJunior(): Observable<any> {
    const {appVersion} = this.store.selectSnapshot(SessionState);

    return this.http.get(
      (semver.satisfies(appVersion, `< ${AWS_FIRST_VERSION}`) ? '/api/v3/auth/customerInfo' : `/${this.config.environment}/authorization/v1/retrieveCustomerInfo`)
    ).pipe( map(getFinalResponseData()) );
  }
}
