import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmationErrorPageComponent} from './confirmation-error-page/confirmation-error-page.component';
import { ButtonModule } from '../button';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    NgxSkeletonLoaderModule
  ],
  declarations: [
    ConfirmationErrorPageComponent
  ],
  exports: [
    ConfirmationErrorPageComponent
  ]
})
export class ConfirmationErrorPageModule {
}
