import {Injectable} from '@angular/core';
import {StorageInterface} from './storage.interface';
import Cookie, {CookieSetOptions} from 'universal-cookie';
import {StorageModule} from './storage.module';

@Injectable({
  providedIn: StorageModule
})
export class CookieStorageService implements StorageInterface {
  private manager: Cookie;

  constructor() {
    this.manager = new Cookie();
  }

  has(key: string): boolean {
    return this.manager.get(key) !== undefined;
  }

  pull(key: string): string | object | any[] {
    return this.manager.get(key);
  }

  push(key: string, value: string | object | any[], params?: CookieSetOptions): void {
    this.manager.set(key, value, params);
  }

  delete(key: string, params?: CookieSetOptions): void {
    this.manager.remove(key, params);
  }
}
