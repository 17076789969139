import {NgModule} from '@angular/core';
import {ReplacePipe} from './replace/replace.pipe';
import {SortPipe} from './sort/sort.pipe';
import {TimeConverterPipe} from './time-converter/time-converter.pipe';
import {SortByDatePipe} from './sortByDate/sortByDate.pipe';

@NgModule({
  declarations: [
    ReplacePipe,
    SortPipe,
    SortByDatePipe,
    TimeConverterPipe,
  ],
  exports: [
    ReplacePipe,
    SortPipe,
    SortByDatePipe,
    TimeConverterPipe
  ]
})
export class PipesModule {

}
