import { Inject, Injectable } from '@angular/core';
import {BaseApiService} from './base-api.service';
import {ApiModule} from './api.module';
import { CORE_CONFIGURATION } from '@myvf/core';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class DeviceApiService {
  constructor(
    private http: BaseApiService,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  create(params: any) {
    return this.http.post('/api/v3/device', params);
  }

  awsCreate(params: any) {
    return this.http.post(`/${this.config.environment}/device/v1/registerDevice`, params);
  }
}
