import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {datadogRum} from '@datadog/browser-rum';
import {datadogConfig} from './datadog.config';
import { buildTimestamp } from 'build-timestamp';

if (environment.production) {
  enableProdMode();
} else {
  console.log('Last build date: ' + buildTimestamp);
}

if(location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
  datadogRum.init(datadogConfig);
  datadogRum.startSessionReplayRecording();
}

document.addEventListener(
  'DOMContentLoaded',
  () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }
);
