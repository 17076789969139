import {LOCALE_ID, NgModule} from '@angular/core';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import localeit from '@angular/common/locales/it';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AemModule, isInEditor} from '@myvf/aem';
import {CORE_CONFIGURATION, CoreModule} from '@myvf/core';
import {AppUiModule} from './app-ui.module';
import {MyvfSharedModule} from '@myvf/shared';

import {NgxsModule} from '@ngxs/store';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';

import {AppRoutingModule} from './app-routing.module';
import {ContainerComponent} from './container/container.component';

import {environment} from '../environments/environment';
import {CustomErrorService} from './core/api/custom-error.service';
import {CustomErrorSharedService} from './shared/errors/custom-error-shared.service';
import {gitVersion} from '../../git-version';

registerLocaleData(localeit);

export function CoreConfigurationFactory() {
  return {
    environment: environment.name,
    apiHost: environment.api,
    apiHostAEM: environment.apiAEM,
    amdocsAEM: environment.amdocsAEM,
    enableTracing: !environment.production,
    isInEditor: !!isInEditor(),
    errorPageNotFound: '/content/myvf-consumer/app/error/page-not-found.html',
    errorPageAccessDenied: '/content/myvf-consumer/app/error/access-denied.html',
    gitVersion: `${gitVersion.branch} - ${gitVersion.commit}`
  };
}

@NgModule({
  declarations: [
    ContainerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'myvf-consumer'}),
    BrowserAnimationsModule,
    NgxsModule.forRoot([], {developmentMode: !environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    AppRoutingModule,
    AppUiModule,
    MyvfSharedModule,
    CoreModule.forRoot(),
    AemModule.forRoot({
      host: environment.aem
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'it'
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: CORE_CONFIGURATION,
      useFactory: CoreConfigurationFactory
    },
    {
      provide: CustomErrorService,
      useClass: CustomErrorSharedService
    }
  ],
  bootstrap: [ContainerComponent]
})
export class AppModule {
}
