import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EaseDirective} from './ease.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EaseDirective],
  exports: [EaseDirective]
})
export class EaseModule {
}
