import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {QuickActionsComponent, SheetDismissDirective,} from './quick-actions/quick-actions.component';
import {QuickActionsProvider} from './quick-actions.provider';

const declarations = [
  QuickActionsComponent,
  SheetDismissDirective,
];
const providers = [QuickActionsProvider];

@NgModule({
  imports: [CommonModule, PortalModule],
  declarations: [...declarations],
  providers: [...providers],
  exports: [...declarations]
})
export class QuickActionsModule {}
