import { Inject, Injectable } from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {ApiModule} from './api.module';
import { HttpParams } from '@angular/common/http';
import { CORE_CONFIGURATION } from '@myvf/core';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class ItemsApiService {
  constructor(
    private http: BaseApiService,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  /**
   * items api get items
   * @param contactId contact id
   * @param items array of string value
   * @param retrieveRecurringChargePeriod optional filtering
   * @param retrieveProductCounters optional filtering
   * @param productTypes array of product types
   * @param noLoader boolean to don't show loader
   */
  retrieveItems(
    contactId: string,
    items: string[],
    retrieveRecurringChargePeriod: boolean = false,
    retrieveProductCounters: boolean = false,
    productTypes: string[] | null = null,
    noLoader: boolean = false
  ): Observable<any> {
    let params = new HttpParams()
      .set('contactId', contactId)
      .set('retrieveRecurringChargePeriod', retrieveRecurringChargePeriod)
      .set('retrieveProductCounters', retrieveProductCounters);
    if (productTypes?.length) {
      productTypes.forEach(v => params = params.append('productTypes', v) );
    }
    if (items?.length) {
      items.forEach(v => params = params.append('items', v) );
    }
    params = params.set('noLoader', noLoader);

    return this.http.get(`/${this.config.environment}/items/v1/retrieveItems`, params);
  }
}
