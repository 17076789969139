import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DigitalContainerTab, FamilyInfoModel } from './digital-container.model';

@Injectable({
  providedIn: 'root'
})

export class DigitalContainerService {
  private eventSubject = new Subject<any>();
  private eventSubjectSimulateClickTab = new Subject<any>();
  private digitalContainerRoutingAnimation;
  private digitalContainerTabs:DigitalContainerTab[] = [];
  private familyInfoData: FamilyInfoModel;


  event$ = this.eventSubject.asObservable();
  eventSimulateClickTab$ = this.eventSubjectSimulateClickTab.asObservable();

  emitRouteChangingEvent(event: any) {
    this.eventSubject.next(event);
  }
  emitSimulateClickTabEvent(event: any) {
    this.eventSubjectSimulateClickTab.next(event);
  }

  getDigitalContainerRoutingAnimation(){
    return this.digitalContainerRoutingAnimation;
  }

  setDigitalContainerRoutingAnimation(routingAnimation:any){
    this.digitalContainerRoutingAnimation = routingAnimation;
  }

  getDigitalContainerTabs() {
    return this.digitalContainerTabs;
  }
  setDigitalContainerTabs(digitalContainerTabs: DigitalContainerTab[]) {
    this.digitalContainerTabs = digitalContainerTabs;
  }

  getAnimationDirection(previousRoute: string, nextRoute: string, animationDirection: string){
    const digitalContainerTabs = this.getDigitalContainerTabs();
    const nextRouteIndex = digitalContainerTabs.findIndex(obj => obj.route.replace('.html', '') === nextRoute);
    const previousRouteIndex = digitalContainerTabs.findIndex(obj => obj.route.replace('.html', '') === previousRoute);
    if (nextRouteIndex < previousRouteIndex) {
      if (animationDirection !== 'left') {
        animationDirection = 'left';
      } else {
        animationDirection = 'left2';
      }
    } else {
      if (animationDirection !== 'right') {
        animationDirection = 'right';
      } else {
        animationDirection = 'right2';
      }
    }

    return animationDirection;
  }

  setFamilyInfoData(data: FamilyInfoModel){
    this.familyInfoData = data;
  }

  getFamilyInfoData(){
    return this.familyInfoData;
  }

}
