import {Inject, Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import { CORE_CONFIGURATION } from '@myvf/core';
import {ApiModule} from './api.module';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class FamilyApiService {

  /**
   *  Constructor
   * @param http api service
   * @param config CORE_CONFIGURATION params
   */
  constructor(
    private http: BaseApiService,
    @Inject(CORE_CONFIGURATION) private config,
  ) {
  }

  /**
   * Get family info for Digital Container Header and Family Group page
   * @param contactId contactId of user
   * @param customerId customerId of user
   * @param noLoader no loader param
   */
  retrieveFamilyInfo(
    contactId: string,
    customerId: string,
    noLoader?: boolean
  ): Observable<any> {
    const params = new HttpParams().set('contactId', contactId).set('customerId', customerId).set('noLoader', noLoader ? 'true' : 'false');
    return this.http.get(`/${this.config.environment}/family/v1/retrieveFamilyInfo`, params);
  }

  /**
   * Get family cost for Family Costs page
   * @param contactId contactId of user
   * @param customerId customerId of user
   * @param noLoader no loader param
   */
  retrieveFamilyCosts(
    contactId: string,
    customerId: string,
    noLoader?: boolean
  ): Observable<any> {
    const params = new HttpParams().set('contactId', contactId).set('customerId', customerId).set('noLoader', noLoader ? 'true' : 'false');
    return this.http.get(`/${this.config.environment}/family/v1/retrieveFamilyCostInfo`, params);
  }

  /**
   * Get family benefits for Family Benefits page
   * @param contactId contactId of user
   * @param item item of user
   * @param noLoader no loader param
   */
  retrieveFamilyBenefits(
    contactId: string,
    item: string,
    noLoader?: boolean
  ): Observable<any> {
    const params = new HttpParams().set('contactId', contactId).set('item', item).set('noLoader', noLoader ? 'true' : 'false');
    return this.http.get(`/${this.config.environment}/family/v1/retrieveItemBenefitInfo`, params);
  }

  /**
 * Get family member info for Iservice Family Member page
 * @param customerId customerId of user
 * @param item msisdn of user
 * @param noLoader no loader param
 */
  retrieveMemberInfo(
    customerId: string,
    item: string,
    contactId: string,
    noLoader?: boolean
  ): Observable<any> {
    const params = new HttpParams().set('customerId', customerId).set('item', item).set('contactId', contactId).set('noLoader', noLoader ? 'true' : 'false');
    return this.http.get(`/${this.config.environment}/family/v1/retrieveMemberInfo`, params);
  }

}
