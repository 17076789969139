export const environment = {
  production: true,
  name: 'prod',
  aem: '', // host AEM
  api: 'https://consumer.api.mvacloud.vodafone.it', // host API
  onPremises: { // On Premises config
    name: 'prod',
    api: 'https://dxl.vodafone.it',
  },
  apiAEM: 'https://mva-consumer.vodafone.it', // host API AEM
  amdocsAEM: 'https://www.vodafone.it',
  resource: 'https://cdn.mvacloud.vodafone.it/content/dam/myvf/webviews',
  moneyManagementPath: 'https://merchant.sparkling18.com/ppt-paygate-infinity/checkout',
  nexiHost: 'https://payments.vodafone.it/vodafone/pagamenti/main',
  deployAssetUrl: 'etc.clientlibs/myvf-consumer/clientlibs/myvf-consumer-fe/resources/assets'
};
