import { CdkPortalOutletAttachedRef, Portal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SwipeAwaySheet } from '../sheet';
import { QuickActionsContext } from '../QuickActionsContext';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[myvf-quick-actions-dismiss]',
})
export class SheetDismissDirective {
  @Input() sheetDismiss: any;
}

@Component({
  selector: 'myvf-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss']
})
export class QuickActionsComponent<TProps> implements AfterViewInit, OnDestroy {
  @ViewChild('sheet') sheet?: ElementRef<HTMLElement>;

  height?: string;

  maxHeight?: string;

  stops: number[] = [];

  contentPortal?: Portal<TProps>;

  private swipeAwaySheet?: SwipeAwaySheet;

  constructor(public readonly context: QuickActionsContext<TProps>) {}

  onInit?: () => void;

  onClose?: (value: any) => void;

  attached(ref: CdkPortalOutletAttachedRef) {
    if (this.context.props && ref instanceof ComponentRef) {
      Object.entries(this.context.props).forEach(([key, value]) => {
        ref.instance[key] = value;
      });
    }
  }

  ngAfterViewInit(): void {
    this.swipeAwaySheet = new SwipeAwaySheet(this.sheet!.nativeElement, {
      context: this.context,
      stops: this.stops,
      onOpen: () => {
        this.context.stateChanged.next((this.context.state = 'open'));
      },
      onClose: (value) => {
        if (this.onClose) {
          this.onClose(value);
        }
        this.context.stateChanged.next((this.context.state = 'closed'));
      },
    });
    if (this.onInit) {
      this.onInit();
    }
  }

  ngOnDestroy() {
    this.swipeAwaySheet!.destroy();
  }

  setValue(value: any) {
    this.swipeAwaySheet!.setValue(value);
  }

  open() {
    this.swipeAwaySheet!.open();
  }

  close(value: any) {
    this.swipeAwaySheet!.close(undefined, value);
  }
}
