import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OneTimePasswordComponent} from './one-time-password/one-time-password.component';
import {MyvfUiModule} from '@myvf/ui';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    OneTimePasswordComponent
  ],
  imports: [
    MyvfUiModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    OneTimePasswordComponent
  ]
})
export class OneTimePasswordModule {
}
