import {gitVersion} from './../git-version';
import {environment} from './environments/environment';

export const datadogConfig: any = {
  applicationId: 'f221e3a7-7ee2-4db7-ac59-f54852728680',
  clientToken: 'pub9deb1767833341a046706da0ac205766',
  site: 'datadoghq.eu',
  service:'myvodafone-consumer',
  sampleRate: (environment.production) ? 10 : 100,
  premiumSampleRate: (environment.production) ? 0 : 100,
  trackInteractions: true,
  defaultPrivacyLevel:'mask-user-input',
  env: environment.name,
  version: `${gitVersion.commit}`,
  trackFrustrations: true,
  excludedActivityUrls: [
    (url) => url.startsWith('https://tags.tiqcdn.com')
  ],
  beforeSend(event) {
    if( event.error ){
      const isNonErrorException =
        event.error.stack.startsWith('SyntaxError: Unexpected token');

      if (isNonErrorException) {
        return false;
      }
    }
  }
};
