import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {AwsAuthorizationState} from './aws-authorization.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      AwsAuthorizationState
    ])
  ],
  providers: []
})
export class AwsAuthorizationModule {
}
