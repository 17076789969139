import {Inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import { CORE_CONFIGURATION, LoggerService, SessionState, SessionStateModel } from '@myvf/core';

@Injectable({
  providedIn: 'root',
  deps: [
    Store,
    Router,
    LoggerService,
    CORE_CONFIGURATION
  ]
})
export class IsLegacyGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private logger: LoggerService,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.config.isInEditor) {
      return true;
    }

    const {user: {isNext}}: SessionStateModel = this.store.selectSnapshot(SessionState);

    if( isNext ) {
      this.logger.group(
        `IsNext Guard: IsNext(${isNext})`
      );
      this.router.navigate([this.config.errorPageAccessDenied]);
    } else {
      return true;
    }
  }
}
