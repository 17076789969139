import { Inject, Injectable } from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {ApiModule} from './api.module';
import { HttpParams } from '@angular/common/http';
import { RetrieveNexiParamRequestModel, checkPaymentOrderParamModel } from '../../mop/shared/mop.model';
import { CORE_CONFIGURATION } from '@myvf/core';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class MopApiService {
  constructor(
    private http: BaseApiService,
    @Inject(CORE_CONFIGURATION) private config,
  ) {
  }

  /**
   * items api get mop items
   * @param contactId contact id
   * @param noLoader boolean to don't show loader
   */
  retrieveMOP(
    contactId: string,
    noLoader: boolean = false
  ): Observable<any> {
    let params = new HttpParams()
      .set('contactId', contactId);
    if (noLoader) {
      params = params.set('noLoader', 'true');
    }

    return this.http.get(`/${this.config.environment}/mop/v1/retrieveMOP`, params);
  }

  /**
   * retrieve nexi param
   * @param request all requested data for nexi param request
   * @param noLoader boolean to don't show loader
   */
  retrieveNexiParam(request: RetrieveNexiParamRequestModel, noLoader: boolean = false, hideError: boolean = false): Observable<any> {
    return this.http.post(`/${this.config.environment}/mop/v1/retrieveNexiParam${(noLoader || hideError) ? '?' : ''}${noLoader ? 'noLoader=true':'' }${hideError ? 'hideError=true':'' }`, request, hideError);
  }

  /**
   * associate nexi payment
   * @param request all requested data for nexi payment request
   * @param noLoader boolean to don't show loader
   */
  associateNexiPayment(request: any, noLoader: boolean = false): Observable<any> {
    return this.http.post(`/${this.config.environment}/mop/v1/associateNexiPayment?hideError=true${noLoader ? '&noLoader=true':'' }`, request, true);
  }

  /**
   * Check Payment Order
   * @param request all requested data for check payment order request
   * @param noLoader boolean to don't show loader
   */

  checkPaymentOrder(
    request: checkPaymentOrderParamModel,
    noLoader?: boolean
  ): Observable<any> {
    return this.http.post(`/${this.config.environment}/mop/v1/checkPaymentOrder?hideError=true${noLoader ? '&noLoader=true': '' }`, request, true);
  }

  /**
 * r1c Nexi Payment
 * @param request all requested data for nexi outcome request
 * @param noLoader boolean to don't show loader
 */
  r1cNexiPayment(request: any, noLoader: boolean = false): Observable<any> {
    return this.http.post(`/${this.config.environment}/mop/v1/r1cNexiPayment?hideError=true${noLoader ? '&noLoader=true':'' }`, request, true);
  }
}
