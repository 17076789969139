import {InjectionToken} from '@angular/core';

export const CORE_CONFIGURATION = new InjectionToken<CoreOptions>('CORE_CONFIGURATION');

export interface CoreOptions {
  environment?: string;
  apiHost?: string;
  secondaryApiHost?: string;
  enableTracing?: boolean;
  isInEditor?: boolean;
  errorPageAccessDenied?: string;
  errorPageNotFound?: string;
}
