import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
// Modules
import {CommonModule} from './common';
import {ApiModule} from './api';
import {AuthModule} from './auth';
import {BridgeModule} from './bridge';
import {StorageModule} from './storage';
import {ErrorsModule} from './errors';
// States
import {SessionState} from './session';
import {UserState} from './user';
// Provides
import {CORE_CONFIGURATION, CoreOptions} from './configuration';
import {AwsAuthorizationModule} from './aws-authorization';

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
    AuthModule,
    AwsAuthorizationModule,
    BridgeModule,
    StorageModule,
    ErrorsModule,
    NgxsModule.forFeature([
      SessionState,
      UserState
    ])
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: CoreOptions): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CORE_CONFIGURATION,
          useValue: config
        }
      ]
    };
  }
}
