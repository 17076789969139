import {EMPTY, Observable, of, Subject} from 'rxjs';
import {delay} from 'rxjs/operators';
import {Injectable, NgZone} from '@angular/core';
import {StickyEvent} from './sticky-event.type';
import {STICKY_DELAY} from './sticky.constants';

export interface StickyServiceEvent {
  show: boolean;
  event?: StickyEvent;
}

@Injectable({
  providedIn: 'root'
})
export class StickyService {

  readonly events: Observable<StickyServiceEvent> = new Subject<StickyServiceEvent>();

  private autoHideTimeout: any;

  constructor(
    private zone: NgZone
  ) {
  }

  open(event: StickyEvent) {
    clearTimeout(this.autoHideTimeout);

    if (event.autoHide) {
      this.zone.run(() => {
        this.autoHideTimeout = setTimeout(
          () => {
            this.close();
          },
          event.autoHide as number
        );
      });
    }

    (this.events as Subject<StickyServiceEvent>).next({show: true, event});

    return of(null).pipe(delay(STICKY_DELAY));
  }

  close(): Observable<any> {
    clearTimeout(this.autoHideTimeout);

    (this.events as Subject<StickyServiceEvent>).next({show: false});

    return of(null).pipe(delay(STICKY_DELAY));
  }
}
