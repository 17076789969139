export enum NativeAchorEnum {
  HOMEPAGE = 'myvfit://view/native/home?x-a=all&pageTitle=Home&overlayType=none',
  LOGIN_ALL = 'myvfit://view/native/login?x-a=all',
  STICKY_CONSENTS_SUCCESS = 'myvfit://sticky/consents-succes',
  STICKY_CONSENTS_ERROR = 'myvfit://sticky/consents-error',
  STICKY_COSTS_SHOWMASKNUMBER = 'myvfit://sticky/costs-showed-masked-number',
  NEWS_SINGLE_PAGE = 'myvfit://view/native/news?identifier=@identifier@&icmp=@icmp@'
}

export const NATIVE_ANCHOR: typeof NativeAchorEnum = NativeAchorEnum;
