import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from './loader.service';
import {Window, WindowService} from '@myvf/core';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(
    private service: LoaderService,
    public window: WindowService,
    @Inject(Window) private nativeWindow: Window
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const locationHrefQuery = this.window.decodeQueryParams(this.window.native.location.href);
    const requestQuery = this.window.decodeQueryParams(req.urlWithParams);
    if (
      (!locationHrefQuery.hasOwnProperty('noLoader') && !requestQuery.hasOwnProperty('noLoader')) ||
      (locationHrefQuery?.noLoader === 'false' || requestQuery?.noLoader === 'false')
    ) {
      this.service.show();
    }

    return next
      .handle(req)
      .pipe(
        finalize(() => {
          this.service.hide();
        })
      );

  }
}
