import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[stickyHost]' // eslint-disable-line @angular-eslint/directive-selector
})
export class StickyHostDirective {
  constructor(
    public viewContainerRef: ViewContainerRef
  ) {
  }
}
