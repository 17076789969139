export const HEADER_ENVIRONMENT = 'X-Bwb-Environment';
// Headers
export const HEADER_CLIENT_ID = 'clientId';
export const HEADER_DEVICE_ID = 'X-Device-Id';
export const HEADER_DEVICE_USER_AGENT = 'X-Device-UserAgent';
export const HEADER_AUTH_TOKEN = 'X-Auth-Token';
export const HEADER_USER_SESSION_ID = 'X-User-SessionId';
export const HEADER_APP_CONSUMER_AUTH = 'vf-a-appconsumerauth';
// Internal header
export const HEADER_API = 'internal-API';
// Cookie
export const COOKIE_TAGGING = 'visitor_id_asset_active';
// Storage
export const STORAGE_SESSION = 'myvf-ses';
export const STORAGE_DEV = 'myvf-dev';
// Client ID
export const CLIENT_ID = '4002';
/**
 * First AWS App Version
 */
export const AWS_FIRST_VERSION = '13.3.0';
/**
 * First MoP App Version
 */
export const MOP_FIRST_VERSION = '13.3.0';
/**
 * First 2FA App Version
 */
export const MFA_FIRST_VERSION = '13.5.0';
