import {Action, State, StateContext} from '@ngxs/store';
import {Save} from './user.action';
import {UserStateModel} from './user.model';
import {Injectable} from '@angular/core';

@State<UserStateModel>({
  name: 'user'
})
@Injectable()
export class UserState {
  @Action(Save)
  save({setState}: StateContext<UserStateModel>, {params}: Save) {
    setState({...params});
  }
}
