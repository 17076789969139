export class AemInitialize {
  static readonly type = '[AEM] initialize';

  constructor(public path: any) {
  }
}

export class AemGetMessages {
  static readonly type = '[AEM] getMessages';

  constructor(public path: string) {
  }
}

export class AemGetMapping {
  static readonly type = '[AEM] getMapping';

  constructor(public path: string) {
  }
}

export class AemGetCategoriesNews {
  static readonly type = '[AEM] getCategoriesNews';

  constructor(public path: string) {
  }
}

export class AemGetCategoriesCatalog {
  static readonly type = '[AEM] getCategoriesCatalog';

  constructor(public path: string) {
  }
}

export class AemGetDigitalContainerData {
  static readonly type = '[AEM] getDigitalContainerData';

  constructor(public path: string) {
  }
}

export class AemGetDigitalBenefitsData {
  static readonly type = '[AEM] getDigitalBenefitsData';

  constructor(public path: string) {
  }
}
