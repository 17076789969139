import {Injectable} from '@angular/core';
import {BridgeManagerModule} from './bridge-manager.module';
import {LoggerService} from '../common/logger.service';
import {WindowService} from '../common/window.service';
import {delay} from '../util.class';

@Injectable({
  providedIn: BridgeManagerModule,
  deps: [
    WindowService,
    LoggerService
  ]
})
export class BridgeConnectorService {
  private readonly pInstance: Promise<object>;
  private pResolve: Function;

  public isWebView = false;

  constructor(
    private window: WindowService,
    private logger: LoggerService
  ) {
    this.init = this.init.bind(this);

    this.pInstance = new Promise(resolve => this.pResolve = resolve);

    this.window.native.BWB_init = this.init;

    this.setIntervalX( () => {
      if (!this.isWebView) {
        this.init();
      }
    },
    () => {
      this.initDefault();
    },
    250,
    10);
  }

  toPromise(): Promise<object> {
    return this.pInstance;
  }

  init(): void {
    if (typeof this.window.native.BWB_JS !== 'undefined') {
      this.isWebView = true;
      this.window.native.BWB_JS.isWebView = this.isWebView;

      this.pResolve(this.window.native.BWB_JS);
    }
  }

  initDefault(): void {
    if (typeof this.window.native.BWB_JS === 'undefined') {
      this.window.native.BWB_JS = {
        isWebView: this.isWebView,
        execute: (name: string, params: any, callbackName: string) => {
          this.logger.warn('BWB_JS doesn\'t work', callbackName);

          delay(() => {
            if (typeof this.window.native[callbackName] === 'function') {
              (this.window.native[callbackName] as Function).apply(this.window.native[callbackName], [{}]);
            }
          }, 200);
        }
      };
    }

    this.pResolve(this.window.native.BWB_JS);
  }

  setIntervalX(callback, fallback, waiting, repetitions) {
    let x = 0;
    callback();
    const intervalID = window.setInterval(function () {

      callback();

      if (++x === repetitions) {
        window.clearInterval(intervalID);
        fallback();
      }
    }, waiting);
  }
}
