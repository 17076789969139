import {NgModule} from '@angular/core';
import {TaggingDirective} from './tagging/tagging.directive';
import {TealiumModule} from './tealium/tealium.module';

@NgModule({
  imports: [
    TealiumModule
  ],
  declarations: [
    TaggingDirective
  ],
  exports: [
    TaggingDirective
  ]
})
export class TaggingModule {
}
