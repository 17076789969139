export class Session {
  static readonly type = '[Auth] Session';
}

export class Register {
  static readonly type = '[Auth] Register';

  constructor(public params: any) {
  }
}

export class Start {
  static readonly type = '[Auth] Start';
}

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public params: { username: string, password: string }, public register: any) {
  }
}

export class SwitchItem {
  static readonly type = '[Auth] SwitchItem';

  constructor(public item: { value: string, type: string }) {
  }
}

export class SwitchSim {
  static readonly type = '[Auth] SwitchSim';

  constructor(public sim: { value: string, type: string }) {
  }
}

export class SwitchNumber {
  static readonly type = '[Auth] SwitchNumber';

  constructor(public number: { value: string, type: string }) {
  }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
