import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable, of} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AgreementsApiService {

  /**
   *  Constructor
   * @param http api service
   */
  constructor(
    private http: BaseApiService
  ) {
  }

  setConsents(data: any): Observable<any> {
    return this.http.post('/api/v3/agreements/save', data);
  }

  getConsents(isSetup: string, data: any): Observable<any> {
    const queryParam = isSetup ? `?isSetup=${isSetup}` : '';

    return this.http.post(`/api/v3/agreements/read${queryParam}`, data);
  }
}
