import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[stickyFooter]' // eslint-disable-line @angular-eslint/directive-selector
})
export class StickyFooterDirective {
  constructor(
    public element: ElementRef,
    private renderer: Renderer2
  ) {
    renderer.addClass(element.nativeElement, 'myvf-sticky-footer');
  }
}
