import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'myvf-ui-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() title: string;
  @Input() serviceNotify = true;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() retry: EventEmitter<void> = new EventEmitter<void>();

  handleClose(event: any): void {
    this.close.emit(event);
  }

  handleRetry(event: any): void {
    this.retry.emit(event);
  }
}
