import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {is} from '@myvf/core';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

@Component({
  selector: 'myvf-ui-iservice-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Input() range = false;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() maxRange: number | null = null;
  @Output() dateSelected: EventEmitter<object> = new EventEmitter<object>();

  private _dateFrom: Date = null;
  private _dateTo: Date = null;

  private firstDaySelection = true;

  // private calendarPage: Array<Array<object>> = [];
  public calendarDays: Array<any> = [];

  public currentMonth: number = null;
  public currentYear: number = null;
  public currentDay: number = null;

  public months: Array<string> = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre'
  ];
  public days: Array<string> = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

  @Input()
  set dateFrom(day: Date) {
    if (is(Date, day)) {
      day.setHours(0, 0, 0, 0);
    }

    this._dateFrom = day;
  }

  get dateFrom(): Date {
    return this._dateFrom;
  }

  @Input()
  set dateTo(day: Date) {
    if (is(Date, day)) {
      day.setHours(0, 0, 0, 0);
    }

    this._dateTo = day;
  }

  get dateTo(): Date {
    return this._dateTo;
  }

  get dayFromTo() {
    const dayFrom = !is(Date, this.dateTo) ? this.dateFrom : this.dateFrom <= this.dateTo ? this.dateFrom : this.dateTo;
    const dayTo = !is(Date, this.dateTo) ? this.dateFrom : this.dateFrom <= this.dateTo ? this.dateTo : this.dateFrom;

    return {dayFrom, dayTo};
  }

  constructor() {
  }

  ngOnInit() {
    const initDate = is(Date, this.dateTo) ? this.dateTo : new Date();
    this.initCalendar(initDate);
  }

  private initCalendar(date: Date) {
    this.setCalendarDate(date);
    this.setCalendarDays(date);
    this.setCalendarRange();
    this.setStartEndClass();
  }

  private setCalendarDate(date: Date) {
    this.currentDay = date.getDate();
    this.currentMonth = date.getMonth();
    this.currentYear = date.getFullYear();
  }

  private setCalendarDays(date: Date) {
    const firstDateOfMonth: Date = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDateOfMonth: Date = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.calendarDays = [];

    let dayFill: Date;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    dayFill = new Date(firstDateOfMonth);
    const fromDay = dayFill.getDay() !== 0 ? dayFill.getDay() : 7;
    for (let i = fromDay - 1; i >= 1; i--) {
      dayFill.setDate(dayFill.getDate() - 1);
      this.calendarDays.push({
        date: new Date(dayFill),
        emptyLabel: true,
        otherMonth: true,
        disabled: true,
        selected: false,
        inPeriod: false,
        today: false,
        start: false,
        end: false
      });
    }

    this.calendarDays.reverse();

    dayFill = new Date(firstDateOfMonth);
    for (let i = 0; i < lastDateOfMonth.getDate(); i++) {
      dayFill.setDate(firstDateOfMonth.getDate() + i);

      this.calendarDays.push({
        date: new Date(dayFill),
        emptyLabel: false,
        otherMonth: false,
        disabled: this.minDate !== null && dayFill < this.minDate || this.maxDate !== null && dayFill > this.maxDate,
        selected: false,
        inPeriod: false,
        today: dayFill.getTime() === today.getTime()
      });
    }

    dayFill = new Date(lastDateOfMonth);
    if (dayFill.getDay() !== 0) {
      for (let i = dayFill.getDay() + 1; i <= 7; i++) {
        dayFill.setDate(dayFill.getDate() + 1);
        this.calendarDays.push({
          date: new Date(dayFill),
          emptyLabel: true,
          otherMonth: true,
          disabled: true,
          selected: false,
          inPeriod: false,
          today: false
        });
      }
    }
  }

  private setCalendarRange() {
    if (!is(Date, this.dateFrom)) {
      return;
    }

    const {dayFrom, dayTo} = this.dayFromTo;

    let rangeMin = null;
    let rangeMax = null;
    if( this.maxRange && dayjs(this.dayFromTo.dayFrom).isSame(this.dayFromTo.dayTo) ) {
      rangeMin = dayjs(this.dayFromTo.dayFrom).startOf('day').subtract(this.maxRange, 'days').toDate();
      rangeMax = dayjs(this.dayFromTo.dayFrom).startOf('day').add(this.maxRange, 'days').toDate();
    }

    for (let i = 0; i < this.calendarDays.length; i++) {
      this.calendarDays[i].selected = false;
      this.calendarDays[i].inPeriod = false;

      if (this.calendarDays[i].date.getTime() === dayFrom.getTime() || this.calendarDays[i].date.getTime() === dayTo.getTime()) {
        this.calendarDays[i].selected = true;
      }

      if (this.calendarDays[i].date.getTime() >= dayFrom.getTime() && this.calendarDays[i].date.getTime() <= dayTo.getTime()) {
        this.calendarDays[i].inPeriod = true;
      }

      if( rangeMin && rangeMax && !(dayjs(this.calendarDays[i].date).isBetween(rangeMin, rangeMax, 'day')) ) {
        this.calendarDays[i].disabled = true;
      }
    }
  }

  private setStartEndClass() {
    if (!is(Date, this.dateFrom) || !is(Date, this.dateTo)) {
      return;
    }

    const {dayFrom, dayTo} = this.dayFromTo;

    this.calendarDays.forEach(item => {
      if (item.date.getTime() === dayFrom.getTime()) {
        item.start = true;
      }

      if (item.date.getTime() === dayTo.getTime()) {
        item.end = true;
      }
    });
  }

  private clearStartEndClass() {
    this.calendarDays.forEach(item => {
      item.start = false;
      item.end = false;
    });
  }

  clickNext() {
    this.initCalendar(new Date(this.currentYear, (this.currentMonth + 1), 1));
  }

  clickPrev() {
    this.initCalendar(new Date(this.currentYear, (this.currentMonth - 1), 1));
  }

  clickDay(day) {
    if (this.firstDaySelection || !this.range) {
      this.dateFrom = day['date'];
      this.dateTo = null;
      this.firstDaySelection = false;
      this.clearStartEndClass();

      if (!this.range) {
        const {dayFrom} = this.dayFromTo;
        this.dateSelected.emit({dayFrom});
      } else {
        const {dayFrom} = this.dayFromTo;
        this.dateSelected.emit({dayFrom, dayTo: dayFrom});
      }
    } else {
      this.dateTo = day['date'];
      this.firstDaySelection = true;

      if (this.dateFrom !== this.dateTo) {
        this.setStartEndClass();
      }

      this.dateSelected.emit(this.dayFromTo);
    }

    this.setCalendarRange();
  }

  quickFilterByMonth() {
    this.initCalendar(this.dateTo);
  }
}
