<div class="calendar">
  <div class="selector">
    <a class="prev-month myvf-icon-arrow rotate-180" id="previousMonth" (click)="clickPrev()"></a>
    <div class="month" id="month">
      <div>{{months[currentMonth]}} - {{currentYear}}</div>
    </div>
    <a class="next-month myvf-icon-arrow" id="nextMonth" (click)="clickNext()"></a>
  </div>
  <div class="day-name-wrap">
    <div *ngFor="let day of days" class="day">{{day}}</div>
  </div>
  <div class="day-wrap">
    <a *ngFor="let day of calendarDays"
         id="{{day.date.getTime()}}"
         class="day"
         [ngClass]="{'disabled': day.disabled, 'otherMonth': day.otherMonth,'selected': day.selected,'in-period': day.inPeriod, 'today': day.today, 'start':day.start, 'end':day.end}"
         (click)="clickDay(day)">
      <div class="day-content"><span *ngIf="!day.emptyLabel; else emptyLabel">{{day.date.getDate()}}</span><ng-template #emptyLabel>&nbsp;</ng-template></div>
    </a>
  </div>
</div>
