export class SendContact {
  constructor(
    public params: { label: string, msisdn: string }
  ) {
  }
}

export class DataFromOverlay {
  constructor(
    public params: any
  ) {
  }
}

export class IsShaking {
  constructor() {
  }
}

export class OnPageFocused {
  constructor() {
  }
}

export type BridgeApiEvent = SendContact | DataFromOverlay | IsShaking | OnPageFocused;

export interface JsActionModel {
  label?: string;
  jsAction?: {
    jsFunc: string,
    jsParams: {
      params: any
    }
  }
}

export interface ShowDrawerModel {
  dismissible: boolean;
  textCenter?: boolean;
  icon?: string;
  title?: string;
  description?: string;
  primaryCta?: JsActionModel,
  dismissAction?: JsActionModel,
  dismissCtaLabel?: string;
}

export interface showOverlayAdditionalParams{
  'x-a'?: string //this will work only on >13.8 client releases
}
